import axios from 'utils/axios';
import store from 'utils/store';

export const appActions = {
    initialize,
    fetchDiameters,
    setDiameters,
};

function initialize(success = null) {
    return function (dispatch) {
        axios
            .get('/api/me')
            .then(response => {
                dispatch({type: 'INITIALIZE', data: response.data});
                success && success();
            })
            .catch(error => {
                dispatch({type: 'INITIALIZE', data: null});
                success && success();
            })
        ;
    }
}

function fetchDiameters(success = null) {
    return function (dispatch) {
        const i = (success) => {
            axios
                .get(`/api/query/find-configuration?name=diameters`)
                .then(response => {
                    dispatch(setDiameters(response.data.value));
                    success && success();
                })
                .catch(r => {
                    if (r.response.status === 500) {
                        axios
                            .post(`/api/commands/create-configuration`, {
                                name: 'diameters',
                                value: '',
                            })
                            .then(_ => {
                                dispatch(setDiameters([]));
                                success && success();
                            })
                    } else {
                        setTimeout(() => i(success), 2000);
                    }
                })
            ;
        };

        i(success);
    }
}

function setDiameters(diameters) {
    return {
        type: 'FETCH_DIAMETERS',
        data: diameters,
    };
}