import React, {useState} from "react";
import {Button, Col, Form, Row, Input, notification, message} from "antd";
import { v4 as uuidv4 } from 'uuid';
import axios from 'utils/axios';
import history from 'utils/history';
import RolesSelect from "components/roles-select";
import RegionSelect from "components/region-select";

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export default function WorkerChangePassword(props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        setLoading(true);

        const payload = {
            user_id: props.user_id,
            password: values.password,
        };

        axios
            .post('/api/commands/change-chosen-user-password', payload)
            .then(response => {
                message.success('Hasło zostało zmienione');
                setLoading(false);
            })
            .catch(error => {
                message.error('Unhandled error');
                setLoading(false);
            })
        ;
    };

    return (
        <Row>
            <Col span={12} push={6}>
                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    <Form.Item name="password" label="Hasło" rules={[{ required: true, min: 5, message: 'Hasło musi mieć conajmniej 5 znaków' }]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label="Powtórz Hasło"
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: 'Powtórz hasło!',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('Hasła nie są identyczne!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Zapisz
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
