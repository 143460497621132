import React from "react";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";

const SignatureWrapper = styled.section`
border: 1px solid rgba(0, 0, 0, 0.7);
margin: 20px 0;
width: ${props => props.width}px;
height: ${props => props.height}px;
`;

function Signature(props) {
    return (
        <SignatureWrapper {...props}>
            <SignatureCanvas
                penColor='green'
                canvasProps={{width: props.width, height: props.height, className: 'sigCanvas'}}
                {...props}
                ref={ref => props.reference && props.reference(ref)}
            />
        </SignatureWrapper>
    );
}

Signature.defaultProps = {
    width: 300,
    height: 100
}

export default Signature