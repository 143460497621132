import React from 'react';
import {Badge} from "antd";

export default function productStatus(props) {
    switch (props.value) {
        case 'not_sharpened':
            return <Badge status="warning" text="Nowy" />;

        default:
            return <Badge status="success" text="Gotowy" />;
    }
}
