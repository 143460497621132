import React, {useState}  from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import ProductForm from 'forms/product';
import {useParams} from "react-router-dom";
import axios from 'utils/axios';
import {Skeleton} from "antd";

function CategoryDetails() {
    const {id} = useParams();
    const [product, setProduct] = useState(null);

    if (!product) {
        axios
            .get(`/api/query/product-definition?productDefinitionId=${id}`)
            .then(response => setProduct(response.data))
        ;

        return (
            <Layout>
                <Skeleton />
            </Layout>
        );
    }

    return (
        <Layout>
            <ProductForm data={product} />
        </Layout>
    )
}

export default requiredAuth(CategoryDetails);
