import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import axios from 'utils/axios';

export default function CustomersSelect(props) {
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        if (customers.length === 0) {
            axios
                .get('/api/query/all-customers')
                .then(response => setCustomers(response.data));
        }
    }, [])

    return (
        <Select
            placeholder="Wybierz klienta"
            loading={customers.length === 0}
            showSearch
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            {...props}
            onChange={value => {
                const customer = customers.find(c => c.id === value);

                props.onChange && props.onChange(value);
                props.onChoose && props.onChoose(customer);
            }}
        >
            {customers.map(customer => <Select.Option key={`customer-${customer.id}`} value={customer.id}>{`${customer.short_name} (${customer.name})`}</Select.Option>)}
        </Select>
    )
}
