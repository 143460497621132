import React from 'react';
import {Button} from "antd";
import styled, {css} from 'styled-components';
import OfflineContext from "offline/context";
import OrderForm from "offline/form";
import axios from "utils/axios";
import {v4 as uuidv4} from "uuid";
import {Link} from 'react-router-dom';

const Status = styled.section`
width: 100%;
padding: 2px;
background: #ff0000;
text-align: center;

${props => props.online &&
    css`
    background: #00ff00;
  `};
`;

const Toolbar = styled.section`
margin-top: 10px;
margin-bottom: 10px;
padding: 0 10px;
`;

const Divider = () => <span style={{display: 'inline-block', width: 10}}/>;
const definitions = localStorage.getItem('definitions') ? JSON.parse(localStorage.getItem('definitions')) : {};
const customers = localStorage.getItem('customers') ? JSON.parse(localStorage.getItem('customers')) : [];
const orders = localStorage.getItem('orders') ? JSON.parse(localStorage.getItem('orders')) : [];

class OfflineScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            online: window.navigator.onLine,
            fetchingData: false,
            exportingData: false,
            definitions,
            customers,
            orders,
        };
    }

    componentDidMount() {
        window.addEventListener('online', () => this.setState({online: true}));
        window.addEventListener('offline', () => this.setState({online: false}));
    }

    fetchDataHandler() {
        this.setState({
            fetchingData: true
        });

        axios
            .get('/api/query/all-product-definitions')
            .then(response => {
                const definitions = {};

                response.data.forEach(product => {
                    if (!definitions[product.category_name]) {
                        definitions[product.category_name] = [];
                    }

                    definitions[product.category_name].push(product);
                });

                this.setState({
                    fetchingData: false,
                    definitions
                }, () => {
                    localStorage.setItem('definitions', JSON.stringify(definitions));
                });
            })
            .catch(error => {
                if (error.response.status === 401) {
                    window.location = '/login';
                }
            })
        ;

        axios
            .get('/api/query/all-customers')
            .then(response => {
                this.setState({
                    fetchingData: false,
                    customers: response.data
                }, () => {
                    localStorage.setItem('customers', JSON.stringify(response.data));
                })
            })
            .catch(error => {
                if (error.response.status === 401) {
                    window.location = '/login';
                }
            })
        ;
    }

    async exportDataHandler() {
        this.setState({
            exportingData: true
        });

        const orders = this.state.orders;
        const notSynced = orders.filter(order => !order.synced);

        for (let i = 0, countNotSynced = notSynced.length; i < countNotSynced; i++) {
            const order = notSynced[i];
            const index = orders.findIndex(o => o.orderId === order.orderId);

            try {
                const payload = {
                    orderId: order.order_id,
                    assigneeId: order.assignee_id,
                    paymentMethod: order.payment_method,
                    deadline: order.deadline,
                    photo: order.photo,
                    products: order.products,
                };
                await axios.post('/api/commands/order-create', payload);
                orders[index].synced = true;
                this.setState({orders}, () => localStorage.setItem('orders', JSON.stringify(this.state.orders)));
            } catch (e) {
                console.log(e);
            }
        }

        setTimeout(() => this.setState({exportingData: false}), 3000);
    }

    addOrder(order) {
        this.setState({
            orders: [
                ...this.state.orders,
                {
                    ...order,
                    synced: false,
                    order_id: uuidv4()
                }
            ]
        }, () => localStorage.setItem('orders', JSON.stringify(this.state.orders)));
    }

    clearOrdersHandler() {
        this.setState({
            orders: []
        }, () => localStorage.setItem('orders', JSON.stringify([])))
    }

    render() {
        const {online, fetchingData, exportingData, definitions, customers, orders} = this.state;
        const syncedOrders = orders.filter(order => order.synced);

        return (
            <OfflineContext.Provider value={{definitions, customers, orders}}>
                <section id="components-layout-demo-custom-trigger" style={{height: '100%'}}>
                    <Status online={online}>{this.state.online ? 'online' : 'offline'}</Status>

                    <Toolbar>
                        <Link to={`/`} disabled={!online}>
                            Wróc do trybu ONLINE
                        </Link>
                        <Divider/>
                        <Button
                            type="default"
                            loading={fetchingData}
                            disabled={!online}
                            onClick={this.fetchDataHandler.bind(this)}
                        >
                            Wczytaj dane
                        </Button>
                        <Divider/>
                        <Button
                            type="primary"
                            loading={exportingData}
                            disabled={!online}
                            onClick={this.exportDataHandler.bind(this)}
                        >
                            Wyeksportuj dane
                        </Button>
                        <Divider/>
                        <Button
                            type="danger"
                            onClick={() => window.confirm('Na pewno chcesz wyczyścić zamówienia?') ? this.clearOrdersHandler() : null}
                        >
                            Wyczyść zamówienia
                        </Button>
                        <Divider/>
                        <span>Zamówienia: {syncedOrders.length}/{orders.length}</span>
                    </Toolbar>

                    <section>
                        <OrderForm addOrder={order => this.addOrder(order)} />
                    </section>
                </section>
            </OfflineContext.Provider>
        )
    }
}
OfflineScreen.contextType = OfflineContext;
export default OfflineScreen;
