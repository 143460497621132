import React from "react";
import {DatePicker, Input} from "antd";
import moment from "moment";
import CustomersSelect from "components/customers-select";
import OrderStatusSelect from "components/order-status-select";
import OrderWorkshopStatusSelect from "components/order-workshop-status-select";
import RegionSelect from "components/region-select";
import UsersSelect from "components/users-select";

export const getFilters = () => {
    return localStorage.getItem('_filters') ? JSON.parse(localStorage.getItem('_filters')) : {
        status: undefined,
        startDate: undefined,
        endDate: undefined,
        clientId: undefined,
        workshopStatus: undefined,
        region: undefined,
        startTakeOfDate: undefined,
        endTakeOfDate: undefined,
        orderIndex: undefined,
        creatorId: undefined,
    }
};

const setFilters = (filters) => localStorage.setItem('_filters', JSON.stringify(filters));

export function convertToQueryString(filters) {
    const filtersKeys = Object.keys(filters);
    let filtersQueryString = '';

    for (let i = 0, count = filtersKeys.length; i <= count; i++) {
        if (filters[filtersKeys[i]]) {
            filtersQueryString += '&'+filtersKeys[i]+'='+filters[filtersKeys[i]];
        }
    }

    return filtersQueryString;
}

export default function Filters(props) {
    const inputWidth = 150;
    const filters = getFilters();
    let filtersComponents = [];

    function _setFilter(filter, value) {
        const filters = getFilters();
        filters[filter] = value;
        setFilters(filters);
        props.onChange && props.onChange(filters);
    }

    filtersComponents = [
        <CustomersSelect style={{width: inputWidth, marginRight: 10}} allowClear={true} onChange={value => _setFilter('clientId', value)} defaultValue={filters.clientId} placeholder="Klient" />,
        <RegionSelect style={{width: inputWidth, marginRight: 10}} allowClear={true} onChange={value => _setFilter('region', value)} defaultValue={filters.region} placeholder="Region" />,
        <OrderWorkshopStatusSelect
            defaultValue={filters.workshopStatus}
            placeholder="Status warsztatowy"
            allowClear={true}
            style={{width: inputWidth, marginRight: 10}}
            onChange={value => _setFilter('workshopStatus', value)}
                />,
        <OrderStatusSelect
            defaultValue={filters.status}
            placeholder="Status zamówienia"
            allowClear={true}
            style={{width: inputWidth, marginRight: 10}}
            onChange={value => _setFilter('status', value)}
        />,
        <DatePicker style={{width: inputWidth, marginRight: 10}} placeholder="Od daty odbioru" allowClear={true} onChange={(date, dateString) => _setFilter('startTakeOfDate', dateString)} defaultValue={filters.startTakeOfDate ? moment(filters.startTakeOfDate, 'YYYY-MM-DD') : undefined} />,
        <DatePicker style={{width: inputWidth, marginRight: 10}} placeholder="Do daty odbioru" allowClear={true} onChange={(date, dateString) => _setFilter('endTakeOfDate', dateString)} defaultValue={filters.endTakeOfDate ? moment(filters.endTakeOfDate, 'YYYY-MM-DD') : undefined} />,
        <DatePicker style={{width: inputWidth, marginRight: 10}} placeholder="Od daty planowanego terminu realizacji" allowClear={true} onChange={(date, dateString) => _setFilter('startDate', dateString)} defaultValue={filters.startDate ? moment(filters.startDate, 'YYYY-MM-DD') : undefined} />,
        <DatePicker style={{width: inputWidth, marginRight: 10}} placeholder="Do daty planowanego terminu realizacji" allowClear={true} onChange={(date, dateString) => _setFilter('endDate', dateString)} defaultValue={filters.endDate ? moment(filters.endDate, 'YYYY-MM-DD') : undefined} />,
        <UsersSelect style={{width: inputWidth, marginRight: 10}} allowClear={true} onChange={value => _setFilter('managerId', value)} defaultValue={filters.managerId} placeholder="Opiekun" />,
        <UsersSelect style={{width: inputWidth, marginRight: 10}} allowClear={true} onChange={value => _setFilter('creatorId', value)} defaultValue={filters.creatorId} placeholder="Osoba wystawiająca zamówienie" />,
        <Input style={{width: inputWidth, marginRight: 10}} allowClear={true} onChange={value => _setFilter('orderIndex', value.target.value)} defaultValue={filters.orderIndex} placeholder="Numer zamówienia" />,
    ];

    props.renderBefore && props.renderBefore.forEach(filter => {
        const filters = getFilters();
        const f = filter.render(value => _setFilter(filter.name, value), filters[filter.name] === undefined ? filter.defaultValue : filters[filter.name]);

        if (filter.atIndex) {
            filtersComponents.splice(filter.atIndex, 0, f);
        } else {
            filtersComponents.push(f);
        }
    });

    return (
        <>
            {filtersComponents}
        </>
    )
}
