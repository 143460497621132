import React from 'react';

export default function OrderWorkshopStatus(props) {
    switch (props.value) {
        case 'new':
            return <span>Nowy</span>;

        case 'in_progress':
            return <span>W trakcie realizacji</span>;

        default:
            return <span>Zrealizowane</span>
    }
}
