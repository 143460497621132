import React, {useState} from 'react';
import {Upload, message, Button} from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import config from 'config';
import axios from 'utils/axios';

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG/PDF file!');
    }
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
        message.error('Image must smaller than 20MB!');
    }
    return isJpgOrPng && isLt20M;
}


export default function UploadPhoto(props) {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);

    function handleChange(info) {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }

        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                setLoading(false);
                setImageUrl(imageUrl);
            });
        }
    }

    function upload(option) {
        setLoading(true);

        const data = new FormData();
        data.append('file', option.file);

        axios
            .post('/api/upload', data)
            .then(response => {
                setLoading(false);
                setImageUrl(config.API_URL+response.data.path);
                props.onChange && props.onChange(response.data);
            })
        ;

    }

    const uploadButton = (
        <Button style={{marginRight: 10}}>
            {loading ? <LoadingOutlined /> : <UploadOutlined />}
        </Button>
    );


    return (
        <Upload
            name="file"
            beforeUpload={beforeUpload}
            onChange={handleChange}
            customRequest={upload}
            style={{paddingRight: 20}}
            className="avatar-uploader"
            showUploadList={false}
        >
            {props.children}
        </Upload>
    );
}
