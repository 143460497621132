import React, {useEffect, useState} from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import axios from 'utils/axios';

import List from 'components/list';
import {compareStrings} from "utils/table";
import {Button} from "antd";
import {Link} from "react-router-dom";
import UserRole from "components/user-role";
import {useQueryParam} from "../utils/use-query-param";

function Categories() {
    const [data, setData] = useState([]);
    const [page, setPage] = useQueryParam('page', 1);
    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(false);

    function fetchCategories(page) {
        setPage(page);
        setLoading(true);

        axios
            .get(`/api/query/product-categories?page=${page}`)
            .then(response => {
                setData(response.data.data);
                setLastPage(response.data.last_page);
                setLoading(false);
            })
        ;
    }

    useEffect(() => {
        fetchCategories(page)
    }, []);

    return (
        <Layout
            headerRightSide={() => <>
                <Link to="/categories/new">
                    <Button type="primary">
                        Dodaj kategorie
                    </Button>
                </Link>
            </>}
        >
            <List
                name="categories"
                fields={['name']}
                labels={{
                    name: 'Nazwa kategorii',
                }}
                data={data}
                page={page}
                lastPage={lastPage}
                onChangePage={page => fetchCategories(page)}
                loading={loading}
            />
        </Layout>
    )
}

export default requiredAuth(Categories);
