import React from 'react';
import { Layout, Menu } from 'antd';
import {connect} from 'react-redux';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    DollarCircleOutlined,
    UserOutlined,
    MailOutlined,
    SnippetsOutlined,
    ToolOutlined,
    CoffeeOutlined,
    RollbackOutlined,
    PoweroffOutlined,
} from '@ant-design/icons';
import './style.css';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Logo from './logo.png';
import history from "utils/history";

const { Header, Sider } = Layout;

const HeaderRightSide = styled.section`
float: right;
margin-right: 20px;
`;

const Content = styled.section`
calc(100% - 40px)
height: 100%;
background: #fff;
margin: 20px;
padding: 20px;
box-sizing: border-box;
`;

class BaseLayout extends React.Component {
    state = {
        collapsed: false,
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        const role = this.props.app.data.role;

        return (
            <Layout id="components-layout-demo-custom-trigger">
                <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                    <div className="logo">
                        <img src={Logo} />
                    </div>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={[history.location.pathname]} defaultOpenKeys={['0', '1']}>
                        {(role === "admin" || role === "sale") ? (
                            <Menu.SubMenu key="0" title={
                                <span>
                              <MailOutlined />
                              <span>Produkty</span>
                            </span>
                            }>
                                <Menu.Item key="/products"><Link to="/products">Produkty</Link></Menu.Item>
                                <Menu.Item key="/categories"><Link to="/categories">Kategorie</Link></Menu.Item>
                                <Menu.Item key="/diameters"><Link to="/diameters">Konfiguracja średnic</Link></Menu.Item>
                            </Menu.SubMenu>
                        ) : null}

                        {(role === "admin" || role === "sale") ? (
                            <Menu.Item key="/">
                                <Link to="/">
                                    <DollarCircleOutlined />
                                    <span>Zamówienia</span>
                                </Link>
                            </Menu.Item>
                        ) : null}

                        {(role === "admin") ? (
                            <Menu.Item key="/workers">
                                <Link to="/workers">
                                    <UserOutlined />
                                    <span>Pracownicy</span>
                                </Link>
                            </Menu.Item>
                        ) : null}

                        {(role === "admin" || role === "sale") ? (
                            <Menu.SubMenu key="1" title={
                                <span>
                              <UserOutlined />
                              <span>Klienci</span>
                            </span>
                            }>
                                <Menu.Item key="/customers"><Link to="/customers">Klienci</Link></Menu.Item>
                                <Menu.Item key="/regions"><Link to="/regions">Regiony</Link></Menu.Item>
                            </Menu.SubMenu>
                        ) : null}

                        {/*{(role === "admin" || role === "sale") ? (*/}
                        {/*    <Menu.Item key="/customers">*/}
                        {/*        <Link to="/customers">*/}
                        {/*            <UserOutlined />*/}
                        {/*            <span>Klienci</span>*/}
                        {/*        </Link>*/}
                        {/*    </Menu.Item>*/}
                        {/*) : null}*/}

                        {(role === "admin" || role === "sharper") ? (
                            <Menu.Item key="/sharping">
                                <Link to="/sharping">
                                    <ToolOutlined />
                                    <span>Ostrzenie</span>
                                </Link>
                            </Menu.Item>
                        ) : null}

                        {(role === "admin" || role === "sale") ? (
                            <Menu.Item key="/reports">
                                <Link to="/reports">
                                    <SnippetsOutlined />
                                    <span>Raporty</span>
                                </Link>
                            </Menu.Item>
                        ) : null}

                        {(role === "admin" || role === "sale") ? (
                            <Menu.Item key="/pickup">
                                <Link to="/pickup">
                                    <RollbackOutlined />
                                    <span>Odbiory</span>
                                </Link>
                            </Menu.Item>
                        ) : null}

                        {(role === "admin" || role === "sale") ? (
                            <Menu.Item key="/offline">
                                <Link to="/offline">
                                    <CoffeeOutlined />
                                    <span>Offline</span>
                                </Link>
                            </Menu.Item>
                        ) : null}

                        <Menu.Item key="/logout">
                            <Link to="/logout">
                                <PoweroffOutlined />
                                <span>Wyloguj</span>
                            </Link>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0 }}>
                        {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: this.toggle,
                        })}

                        <HeaderRightSide style={{width: 'calc(100% - 100px)', display: 'block', overflowX: 'scroll'}}>
                            <div style={{whiteSpace: 'nowrap'}}>
                                {this.props.headerRightSide ? this.props.headerRightSide() : null}
                            </div>
                        </HeaderRightSide>
                    </Header>
                    <Content
                        className="site-layout-background"
                    >
                        {this.props.children}
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
    }
};
export default connect(mapStateToProps)(BaseLayout);
