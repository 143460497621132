import React from 'react';
import {Provider} from "react-redux";
import store from './store';

const StoreProvider = (props) => (
    <Provider store={store}>
        {props.children}
    </Provider>
);

export default StoreProvider;
