import config from 'config';
import axios from 'axios';
import NProgress from "nprogress";
import qs from 'qs';

export function getAuthCredentials() {
    const credentialsString = localStorage.getItem('auth_credentials');
    if(!credentialsString) return null;
    return JSON.parse(credentialsString);
}

const credentials = getAuthCredentials();
let defaultHeaders = {
    'Accept-Language': 'en',
};

if(credentials) {
    defaultHeaders = Object.assign(defaultHeaders, {
        'Authorization': 'Bearer '+credentials.token.access_token,
    });
}

const instance = axios.create({
    baseURL: config.API_URL,
    headers: defaultHeaders,
    withCredentials: true
});

let isTryingToRefreshToken = false;

const getRefreshToken = (limit = 3, i = 0) => {
    const getToken = () => new Promise((resolve, reject) => {
        const credentials = getAuthCredentials();

        if(!credentials) return;

        isTryingToRefreshToken = true;
        instance
            .post('/oauth/v2/token', qs.stringify({
                'client_id': config.APP_CLIENT_ID,
                'client_secret': config.APP_CLIENT_SECRET,
                'grant_type': 'refresh_token',
                'refresh_token': credentials.token.refresh_token
            }))
            .then(response => {
                isTryingToRefreshToken = false;

                const token = response.data;
                const data = {
                    token,
                    remember: credentials.remember,
                };

                localStorage.setItem('auth_credentials', JSON.stringify(data));
                resolve(data);
            })
            .catch(error => {
                if(localStorage.getItem('auth_credentials') && (error.response.data.error_description === "Invalid refresh token" || error.response.data.error_description === "Refresh token has expired")) {
                    localStorage.removeItem('auth_credentials');
                    window.location = '/login';
                }

                reject(error);
            });
    });

    return new Promise((resolve, reject) => {
        if(isTryingToRefreshToken && i++ < limit) {
            setTimeout(() => resolve(getRefreshToken(limit, i)), 1000);
        } else if(isTryingToRefreshToken === false && i === 0) {
            getToken().then(token => resolve(token));
        } else if(isTryingToRefreshToken === false && i > 0) {
            resolve(getAuthCredentials());
        } else {
            reject('Cannot receive token.');
        }
    });
};

instance.interceptors.request.use(config => {
    if(!config.data || (config.data && config.data.quiet !== true)) {
        NProgress.start();
    }

    const credentials = getAuthCredentials();
    if(credentials) {
        config.headers['Authorization'] = 'Bearer '+credentials.token.access_token;
    }

    return config;
});

instance.interceptors.response.use(response => {
    // if (response.data && response.data.data && response.data.data.contents && response.data.data.report_id) {
    //     response.data.data.contents = JSON.parse(response.data.data.contents);
    // }

    return response;
}, error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry && localStorage.getItem('auth_credentials')) {
        originalRequest._retry = true;
        return getRefreshToken().then(response => {
            const credentials = response;
            instance.defaults.headers.common['Authorization'] = 'Bearer ' + credentials.token.access_token;
            originalRequest.headers['Authorization'] = 'Bearer ' + credentials.token.access_token;
            return instance(originalRequest);
        });
    } else {
        // if(localStorage.getItem('auth_credentials')) {
        //     localStorage.removeItem('auth_credentials');
        //     window.location = '/login';
        // }
    }

    return Promise.reject(error);
});

const calculatePercentage = (loaded, total) => (Math.floor(loaded * 1.0) / total);
instance.interceptors.response.use(response => {
    NProgress.isStarted() && NProgress.done(true);
    return response;
});

instance.defaults.onDownloadProgress = e => {
    const percentage = calculatePercentage(e.loaded, e.total);
    NProgress.isStarted() && NProgress.set(percentage)
};

export default instance;
