import React, {useState}  from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import OrderForm from 'forms/order';

function CreateOrder() {
    return (
        <Layout>
            <OrderForm />
        </Layout>
    )
}

export default requiredAuth(CreateOrder);
