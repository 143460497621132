import React, {useState} from 'react';
import {Button, Form, Input, Space, Table} from 'antd';
import styled from 'styled-components';
import PaidSelect from "components/paid-select";
import ReceivedSelect from "components/received-select";
import ProductStatus from "components/order/ProductStatus";
import SignatureCanvas from 'react-signature-canvas';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';
import Signature from "components/signature";
import NotesInput from "components/notes-input";
import AdditionalNotesInput from "components/additional-notes-input";

const ProductsWrapper = styled.section`
margin-top: 20px;
`;

export default function CompleteOrder(props) {
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedProducts(selectedRows);
        }
    };

    const [form] = Form.useForm();
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [processing, setProcessing] = useState(false);

    function complete(values) {
        setProcessing(true);
        axios
            .post('/api/commands/create-document', {
                document_id: uuidv4(),
                order_id: props.order.order.id,
                invoiceNumber: values.invoice_number,
                invoiceAmount: values.invoice_amount,
                received: false,
                paid: false,
                products: selectedProducts.map(product => product.id)
            })
            .then(() => {
                props.close();
                props.afterClose();
                setProcessing(false);
            })
            .catch(() => {
                setProcessing(false);
            })
        ;
    }

    return (
        <>
            <Form
                form={form}
                name="register"
                onFinish={complete}
                scrollToFirstError
            >
                <Space>
                    <Form.Item name="invoice_number" label="Numer faktury" style={{ display: 'inline-block' }}>
                        <Input style={{width: 150}} />
                    </Form.Item>

                    <Form.Item name="invoice_amount" label="Kwota" style={{ display: 'inline-block' }}>
                        <Input style={{width: 150}} />
                    </Form.Item>
                </Space>

                <ProductsWrapper>
                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                        columns={[
                            {title: 'Produkt', dataIndex: 'name', key: 'name'},
                            {title: 'Status', dataIndex: 'status', key: 'status', render: value => <ProductStatus value={value} />},
                            {title: 'Ilość', dataIndex: 'quantity', key: 'quantity'},
                            {title: 'Uwagi', dataIndex: 'notes', key: 'notes', align: 'right', render: (notes, product) => <NotesInput defaultValue={notes} tool={product} />},
                            {title: 'Uwagi od warsztatu', dataIndex: 'additional_notes', key: 'additional_notes', align: 'right', render: (notes, product) => <AdditionalNotesInput defaultValue={notes} tool={product} />},
                        ]}
                        dataSource={props.order.products.filter(product => !product.document_id && product.status === "sharpened")}
                        pagination={false}
                        rowKey="id"
                    />
                </ProductsWrapper>

                <Button onClick={() => props.close && props.close()} style={{marginTop: 20, marginRight: 20}}>
                    Anuluj
                </Button>

                <Button type="primary" htmlType="submit" loading={processing} style={{marginTop: 20}} disabled={processing || selectedProducts.length === 0}>
                    Realizuj
                </Button>
            </Form>
        </>
    )
}
