import {
    createStore,
    applyMiddleware,
    combineReducers,
    compose
} from 'redux';
import thunk from 'redux-thunk';
import app from 'reducers/app';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({
    app
});

const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));
export default store;
