import React from 'react';
import {connect} from 'react-redux';

export const mapStateToProps = (state) => {
    return {
        app: state.app,
    }
};

const requiredAuth = (Component) => {
    class RequiredAuth extends React.PureComponent {
        componentDidMount() {
            this.optionalRedirect();
        }

        optionalRedirect() {
            if(!this.props.app.data) {
                this.props.history.push('/login');
            }
        }

        render() {
            const {app, ...props} = this.props;

            if (app.data && app.data.role === "sharper" && this.props.match.path !== "/sharping") {
                window.location.href = "/sharping";
                return null;
            }

            return app.data ? <Component {...props}/> : null;
        }
    }

    return connect(mapStateToProps)(RequiredAuth);
};

export default requiredAuth;
