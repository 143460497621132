import React from 'react';

export default function PaymentMethod(props) {
    switch (props.value) {
        case 'cash':
            return <span>Gotówka</span>;

        case 'transfer':
            return <span>Przelew</span>;

        case 'collective':
            return <span>Zbiorcza FV</span>;

        default:
            return <span>Za pobraniem</span>;
    }
}
