import React, {useState}  from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import axios from 'utils/axios';

import List from 'components/list';
import {compareStrings} from "utils/table";
import {Button, Menu, Dropdown} from "antd";
import { DownOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";
import UserRole from "components/user-role";

import Store from 'utils/store';

function Workers() {
    const [data, setData] = useState([]);
    const [lock, setLock] = useState(false);
    const role = Store.getState().app.data.role;

    if (!lock) {
        setLock(true);

        axios
            .get('/api/query/all-users')
            .then(response => setData(response.data))
        ;
    }

    return (
        <Layout
            headerRightSide={() => <>
                <Link to="/workers/new">
                    <Button type="primary">
                        Dodaj pracowniika
                    </Button>
                </Link>
            </>}
        >
            <List
                name="workers"
                fields={['first_name', 'roles', 'actions']}
                render={{
                    first_name: (value, worker) => `${worker.first_name} ${worker.last_name}`,
                    roles: (value, worker) => <UserRole role={worker.roles} />,
                    actions: (value, worker) => {
                        if (role !== 'admin') return null;

                        const menu = (
                            <Menu>
                                <Menu.Item key="1"><Link to={`/workers/${worker.id}`}>Zmień dane</Link></Menu.Item>
                                <Menu.Item key="2"><Link to={`/workers/${worker.id}/change-password`}>Zmień hasło</Link></Menu.Item>
                            </Menu>
                        );

                        return (
                            <Dropdown overlay={menu}>
                                <Button>
                                    Akcje <DownOutlined />
                                </Button>
                            </Dropdown>
                        );
                    }
                }}
                sort={{
                    first_name: {
                        compare: (a, b) => compareStrings(a, b, 'first_name'),
                    }
                }}
                labels={{
                    first_name: 'Pracownik',
                    roles: 'Funkcja',
                    actions: ' ',
                }}
                alignRight={['roles', 'actions']}
                data={data}
                hideActions={true}
            />
        </Layout>
    )
}

export default requiredAuth(Workers);
