import React from 'react';
import {Table} from 'antd';
import ProductStatus from "./order/ProductStatus";

export default function OrdersToolsList(props) {
    return <Table columns={[
        {
            title: 'Nazwa narzędzia',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <ProductStatus value={status} />
        },
        {
            title: 'Otwór',
            dataIndex: 'hole',
            key: 'hole'
        },
        {
            title: 'Średnica',
            dataIndex: 'diameter',
            key: 'diameter'
        },
        {
            title: 'Ilość',
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: 'Uwagi',
            dataIndex: 'notes',
            key: 'notes',
            align: 'right',
            render: (notes, tool) => <span>{notes}</span>,
        },
        {
            title: 'Uwagi od warsztatu',
            dataIndex: 'additional_notes',
            key: 'additional_notes',
            align: 'right',
            render: (notes, tool) => <span>{notes}</span>,
        },
    ]} dataSource={props.data} size="small" />;
}