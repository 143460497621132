import React, {useState} from "react";
import {Button, Col, Form, Row, Input, Select, notification} from "antd";
import { v4 as uuidv4 } from 'uuid';
import axios from 'utils/axios';
import history from 'utils/history';

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export default function Region(props) {
    const {data} = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        setLoading(true);

        if (data) {
            axios
                .post('/api/commands/region-update', {
                    regionId: data.id,
                    region: values.region,
                })
                .then(response => {
                    notification['success']({
                        message: 'Dane zostały zapisane!',
                    });
                    setLoading(false);
                })
                .catch(error => setLoading(false))
            ;
        } else {
            const payload = {
                regionId: uuidv4(),
                region: values.region,
            };

            axios
                .post('/api/commands/region-create', payload)
                .then(response => {
                    history.push(`/regions/${payload.regionId}`);
                })
                .catch(error => setLoading(false))
            ;
        }
    };

    return (
        <Row>
            <Col span={12} push={6}>
                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                    initialValues={data}
                >
                    <Form.Item name="region" label="Region" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {data ? 'Zapisz' : 'Utwórz region'}
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
