import React, {useState} from "react";
import moment from "moment";
import {DatePicker, Popconfirm, List as AntdList, Button} from "antd";
import {RetweetOutlined} from '@ant-design/icons';

export default function DocumentStatus(props) {
    const [status, setStatus] = useState(props.status);
    const [hasChanged, setHasChanged] = useState(false);

    function toggle() {
        props.onToggle && props.onToggle(!status);
        setStatus(!status);
        setHasChanged(true);
    }

    let date = status ? (props.date ? moment(props.date) : moment()) : null;

    return (
        <>
            {status ? 'Tak' : 'Nie'}
            {(props.date || hasChanged) ? <small> ({date.format('DD-MM-YYYY')})</small> : <small> (-)</small>}

            {!status ? (
                <Popconfirm title="Jesteś pewny, że chcesz zmienić status?" onConfirm={toggle}>
                    <Button type="link">
                       <RetweetOutlined />
                    </Button>
                </Popconfirm>
            ) : null}
        </>
    )
}
