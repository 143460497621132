import React, {useState}  from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import axios from 'utils/axios';

import List from 'components/list';
import {compareStrings} from "utils/table";
import {Button} from "antd";
import {Link} from "react-router-dom";

function Regions() {
    const [data, setData] = useState([]);
    const [lock, setLock] = useState(false);

    if (!lock) {
        setLock(true);

        axios
            .get('/api/query/all-regions')
            .then(response => setData(response.data))
        ;
    }

    return (
        <Layout
            headerRightSide={() => <>
                <Link to="/regions/new">
                    <Button type="primary">
                        Dodaj region
                    </Button>
                </Link>
            </>}
        >
            <List
                name="regions"
                fields={['region']}
                sort={{
                    region: {
                        compare: (a, b) => compareStrings(a, b, 'region'),
                    }
                }}
                labels={{
                    region: 'Region',
                }}
                data={data}
            />
        </Layout>
    )
}

export default requiredAuth(Regions);
