import React  from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import WorkerChangePasswordForm from 'forms/worker-change-password';
import {useParams} from "react-router-dom";

function WorkerChangePassword(props) {
    const {id} = useParams();

    return (
        <Layout>
            <WorkerChangePasswordForm user_id={id} />
        </Layout>
    )
}

export default requiredAuth(WorkerChangePassword);
