import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import axios from 'utils/axios';

export default function ProductCategorySelect(props) {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        axios
            .get('/api/query/all-product-categories')
            .then(response => setCategories(response.data));
    }, []);

    return (
        <Select
            placeholder="Wybierz kategorię"
            loading={categories.length === 0}
            showSearch
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            {...props}
            onChange={value => {
                const category = categories.find(c => c.id === value);

                props.onChange && props.onChange(value);
                props.onChoose && props.onChoose(category);
            }}
        >
            {categories.map(category => <Select.Option key={`category-${category.id}`} value={category.id}>{`${category.name}`}</Select.Option>)}
        </Select>
    )
}
