import React, {useEffect, useState} from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import axios from 'utils/axios';

import List from 'components/list';
import {compareStrings} from "utils/table";
import {Button} from "antd";
import {Link} from "react-router-dom";
import UserRole from "components/user-role";
import {useQueryParam} from "../utils/use-query-param";

function Products() {
    const [data, setData] = useState([]);
    const [page, setPage] = useQueryParam('page', 1);
    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(false);

    function fetchProducts(page) {
        setPage(page);
        setLoading(true);

        axios
            .get(`/api/query/product-definitions?page=${page}`)
            .then(response => {
                setData(response.data.data);
                setLastPage(response.data.last_page);
                setLoading(false);
            })
        ;
    }

    useEffect(() => {
        fetchProducts(page)
    }, []);

    return (
        <Layout
            headerRightSide={() => <>
                <Link to="/products/new">
                    <Button type="primary">
                        Dodaj produkt
                    </Button>
                </Link>
            </>}
        >
            <List
                name="products"
                fields={['name', 'category_name']}
                labels={{
                    name: 'Nazwa produktu',
                    category_name: 'Nazwa kategorii',
                }}

                sort={{
                    name: {
                        compare: (a, b) => compareStrings(a, b, 'name'),
                    }
                }}
                data={data}
                loading={loading}
            />
        </Layout>
    )
}

export default requiredAuth(Products);
