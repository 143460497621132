import React from 'react';
import {Button, Table, Pagination} from 'antd';
import {Link} from 'react-router-dom';

export default function list(props) {
    const columns = props.fields.map(field => {
        const column = {
            title: field,
            dataIndex: field
        };

        if (props.sort && props.sort[field]) {
            column.sorter = props.sort[field];
        }

        if (props.render && props.render[field]) {
            column.render = props.render[field];
        }

        if (props.labels && props.labels[field]) {
            column.title = props.labels[field];
        }

        if (props.fixed && props.fixed[field]) {
            column.fixed = props.fixed[field];
        }

        if (props.alignRight && props.alignRight.indexOf(field) !== -1) {
            column.align = 'right';
        }

        return column;
    });

    if (props.hideActions !== true) {
        columns.push({
            title: '',
            align: 'right',
            render: (value, record) => {
                return (
                    <>
                        <Link to={`/${props.name}/${record.id}`}>
                            <Button>Szczegóły</Button>
                        </Link>
                    </>
                )
            }
        });
    }

    const options = {
        dataSource: props.data,
        columns
    };

    if (props.scroll) {
        options.scroll = props.scroll;
    }

    if (props.page && props.lastPage) {
        options.pagination = false;
    }

    if (props.expandable) {
        options.expandable = props.expandable;
    }

    if (props.rowKey) {
        options.rowKey = props.rowKey;
    }

    if (!options.pagination) {
        options.pagination = {defaultPageSize: 20};
    }

    const defaultPageSize = 20;

    return (
        <div style={{overflow: 'hidden'}}>
            <Table
                {...options}
                loading={props.loading ? props.loading : false}
            />
            {props.page && props.lastPage ? <Pagination showSizeChanger={false} defaultCurrent={parseInt(props.page)} current={parseInt(props.page)} total={props.lastPage * defaultPageSize} defaultPageSize={defaultPageSize} style={{marginTop: 20}} onChange={(page) => {
                props.onChangePage && props.onChangePage(page);
            }} /> : null}
        </div>
    )
}
