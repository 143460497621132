import React, {useState} from "react";
import UploadPhoto from "../../upload-photo";
import config from "../../../config";
import {UploadOutlined} from '@ant-design/icons';
import './index.css';
import axios from "../../../utils/axios";

const OrderItemPhoto = (props) => {
    const [photos, setPhotos] = useState(props.photos ? props.photos : [])

    const addPhoto = (photo) => {
        const _p = [...photos, photo];
        setPhotos(_p);

        if (props.append && props.productId) {
            axios
                .post('/api/commands/add-product-photo', {
                    productId: props.productId,
                    photo,
                })
                .then(response => {
                    console.log(response);
                })
            ;
        }

        if (props.onChange) {
            props.onChange(_p)
        }
    }

    return (
        <div className={`order-item-photos-wrapper ${props.classNameWrapper}`}>
            <div className="order-item-photos">
                {photos.map((photo, i) => {
                    if (photo.path.match(/\.pdf$/)) {
                        return <a href={config.API_URL+photo.path} target="_blank" className="order-item-photos__item order-item-photos__item--pdf" key={photo.path}>PDF</a>;
                    }
                    return <a href={config.API_URL+photo.path} target="_blank" className="order-item-photos__item" key={photo.path} style={{backgroundImage: `url("${config.API_URL+photo.path}")`}} />;
                })}

                <a className="order-item-photos__item order-item-photos__item--upload">
                    <UploadPhoto onChange={data => addPhoto(data)}>
                        <UploadOutlined />
                    </UploadPhoto>
                </a>
            </div>
        </div>
    )
}

OrderItemPhoto.defaultProps = {
    append: false,
    classNameWrapper: '',
}

export default OrderItemPhoto;