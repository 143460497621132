import React from 'react';
import {connect} from 'react-redux';

export const mapStateToProps = state => {
    return {
        app: state.app,
    }
};

const requiredNoAuth = Component => {
    class RequiredNoAuth extends React.PureComponent {
        componentDidMount(): void {
            this.optionalRedirect();
        }

        optionalRedirect(): void {
            if(this.props.app.data) {
                this.props.history.push('/');
            }
        }

        render() {
            const {app, ...props} = this.props;
            return !app.data ? <Component {...props}/> : null;
        }
    }

    return connect(mapStateToProps)(RequiredNoAuth);
};

export default requiredNoAuth;
