import React from 'react';
import {Select} from "antd";

export default function RolesSelect(props) {
    return (
        <Select placeholder="Funkcja" {...props}>
            <Select.Option value="admin">Admin</Select.Option>
            <Select.Option value="sharper">Pracownik</Select.Option>
            <Select.Option value="sale">Handlowiec</Select.Option>
        </Select>
    )
}
