import React, {useState} from "react";
import {Button, Col, DatePicker, Form, InputNumber, Popconfirm, Row, Input, notification} from "antd";
import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import PaymentMethodSelect from "components/payment-method-select";
import ProductDefinitionSelect from 'offline/ProductDefinitionSelect'
import CustomersSelect from "offline/CustomerSelect";
import moment from "moment";
import OfflineContext from "offline/context";
import DiameterSelect from "../components/diameter-select";

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 24,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 18,
            offset: 8,
        },
    },
};

const ProductRow = styled.section`
display: flex;
flex-direction: row;
margin-bottom: 10px;
`;

export default class extends React.Component {
    static contextType = OfflineContext;

    constructor(props) {
        super(props);

        this.state = {
            products: [...Array(5)].map((_, i) => ({id: null, quantity: 1, hole: null, diameter: null, notes: null}))
        }
    }

    addProductRow() {
        this.setState({
            products: [...this.state.products, {id: null, quantity: 1, hole: null, diameter: null, notes: null}]
        })
    }

    removeProductRow(index) {
        const p = [...this.state.products];
        p.splice(index, 1);
        this.setState({
            products: p,
        });
    }

    changeQuantity(index, value) {
        const p = [...this.state.products];
        p[index].quantity = value;
        this.setState({
            products: p,
        });
    }

    changeHole(index, value) {
        const p = [...this.state.products];
        p[index].hole = value.target.value;
        this.setState({
            products: p,
        });
    }

    changeDiameter(index, value) {
        const p = [...this.state.products];
        p[index].diameter = value;
        this.setState({
            products: p,
        });
    }

    changeNotes(index, value) {
        const p = [...this.state.products];
        p[index].notes = value.target.value;
        this.setState({
            products: p,
        });
    }

    changeProduct(index, value) {
        const p = [...this.state.products];
        p[index].id = value;
        this.setState({
            products: p,
        });
    }

    onFinish(values) {
        if (this.props.data) {
        } else {
            const order = {
                ...values,
                products: this.state.products.filter(p => p.id !== null)
            };

            this.props.addOrder(order);
        }
    };

    render() {
        const {data} = this.props;
        const normalizedData = data ? {
            ...data.order,
            deadline: data.order.deadline ? moment(data.order.deadline) : null,
        } : null;

        return (
            <Row>
                <Col span={24}>
                    <Form
                        {...formItemLayout}
                        name="register"
                        form={this.props.form}
                        onFinish={this.onFinish.bind(this)}
                        scrollToFirstError
                        initialValues={normalizedData}
                    >
                        {data ? <Form.Item name="order_index" label="Id zamówienia">
                            <span>{data.order.order_index}</span>
                        </Form.Item> : null}

                        {data ? <Form.Item name="creation_date" label="Data odbioru">
                            <span>{moment(data.order.creation_date).format('DD-MM-YYYY')}</span>
                        </Form.Item> : null}


                        <Form.Item name="assignee_id" label="Klient" rules={[{ required: true }]}>
                            <CustomersSelect disabled={data} onChoose={value => this.props.form.setFieldsValue({payment_method: value.payment_method})} />
                        </Form.Item>

                        <Form.Item name="payment_method" label="Rodzaj płatności" rules={[{ required: true }]}>
                            <PaymentMethodSelect />
                        </Form.Item>

                        <Form.Item name="deadline" label="Planowany termin oddania">
                            <DatePicker placeholder="Wybierz termin" />
                        </Form.Item>

                        {
                            !data ? (
                                <Form.Item label="Narzędzia">
                                    {
                                        this.state.products.map((product, i) => (
                                            <ProductRow>
                                                <ProductDefinitionSelect size="large" onChange={value => this.changeProduct(i, value)} style={{maxWidth: 250}} />
                                                <DiameterSelect  placeholder="Średnica" value={product.diameter} style={{maxWidth: 100, marginLeft: 10}} onChange={value => this.changeDiameter(i, value)} size="large" />
                                                <Input placeholder="Otwór" value={product.hole} style={{maxWidth: 100, marginLeft: 10}} onChange={value => this.changeHole(i, value)} />
                                                <InputNumber size="large" min={1} max={100000} value={product.quantity} style={{marginLeft: 10, marginRight: 10}} onChange={value => this.changeQuantity(i, value)} />
                                                <Input placeholder="Uwagi" value={product.notes} style={{maxWidth: 200, marginRight: 10}} onChange={value => this.changeNotes(i, value)} />

                                                <Popconfirm title="Na pewno chcesz usunąć" onConfirm={() => this.removeProductRow(i)}>
                                                    <Button type="danger" size="large">
                                                        <DeleteOutlined />
                                                    </Button>
                                                </Popconfirm>
                                            </ProductRow>
                                        ))
                                    }
                                    <Button size="small" onClick={this.addProductRow.bind(this)}>Dodaj produkt</Button>
                                </Form.Item>
                            ) : null
                        }

                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">
                                {data ? 'Zapisz' : 'Utwórz zamówienie'}
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        );
    }
}
