import React, {useEffect, useState} from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import axios from 'utils/axios';
import List from 'components/list';
import {CheckCircleOutlined, DownOutlined} from '@ant-design/icons';
import {List as AntdList, Typography, Button, Dropdown, Menu, Space, Tooltip, DatePicker} from "antd";
import moment from 'moment';
import PaymentMethod from "components/payment-method";
import OrderStatus from "components/order/Status";
import NotesInput from "components/notes-input";
import {Link} from 'react-router-dom';
import OrderWorkshopStatus from "components/order-workshop-status";
import config from "config";
import SignDocumentModal from "modal/sign-document";
import DocumentStatus from 'components/document-status';
import {orderUtils} from "utils/order-utils";
import Filters, {convertToQueryString, getFilters} from "utils/filters";
import {useQueryParam} from "../utils/use-query-param";
import ProductStatusSelect from "../components/product-status-select";
import ProductCategorySelect from "../components/product-category-select";

const Text = Typography;

function Pickup() {
    const [data, setData] = useState([]);
    const [page, setPage] = useQueryParam('page', 1);
    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [signModalUuid, setSignModalUuid] = useState(false);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetchProducts(page, getFilters());
    }, []);

    function fetchProducts(page, filters = {}) {
        setLoading(true);
        setPage(page);

        axios
            .get(`/api/query/get-documents-by-filters?page=${page+convertToQueryString(filters)}`)
            .then(response => {
                setData(response.data.data);
                setLastPage(response.data.last_page);
                setLoading(false);
            })
        ;
    }

    function DocumentProducts(props) {
        return (
            <AntdList
                bordered
                dataSource={props.data}
                renderItem={item => (
                    <AntdList.Item>
                        {item.quantity} x {item.name}
                    </AntdList.Item>
                )}
            />
        )
    }

    function PdfButtons(props) {
        const [loadingDownloadPdfButton, setLoadingDownloadPdfButton] = useState(false);
        const [loadingSendPdfButton, setLoadingSendPdfButton] = useState(false);

        function downloadPdf(id) {
            setLoadingDownloadPdfButton(true);
            orderUtils.downloadWZPdf(id, () => setLoadingDownloadPdfButton(false), () => setLoadingDownloadPdfButton(false));
        }

        function sendPdf(id) {
            setLoadingSendPdfButton(true);
            orderUtils.sendWZPdf(id, () => setLoadingSendPdfButton(false), () => setLoadingSendPdfButton(false));
        }

        const menu = (
            <Menu>
                <Menu.Item key="1" onClick={() => downloadPdf(props.document.id)}>Pobierz</Menu.Item>
                <Menu.Item key="2" onClick={() => sendPdf(props.document.id)}>Wyślij do klienta</Menu.Item>
            </Menu>
        );


        return (
            <Space>
                <Dropdown overlay={menu}>
                    <Button loading={loadingDownloadPdfButton || loadingSendPdfButton}>
                        Dokument WZ <DownOutlined />
                    </Button>
                </Dropdown>

                {parseInt(props.document.sent) === 1 ? (
                    <Tooltip title="Dokument został wysłany">
                        <CheckCircleOutlined style={{color: '#6ed349'}} />
                    </Tooltip>
                ) : null}
            </Space>
        );
    }

    const handleSign = (document) => {
        setProducts(document.products);
        setSignModalUuid(document.id);
    }

    return (
        <Layout
            headerRightSide={() => <Filters
                onChange={filters => fetchProducts(1, filters)}
                renderBefore={[
                    {
                        name: 'startPaidAt',
                        defaultValue: undefined,
                        // atIndex: 4,
                        render: (setFilter, defaultValue) => <DatePicker
                            style={{width: 200, marginRight: 10}}
                            placeholder="Od daty opłacenia"
                            allowClear={true}
                            onChange={(_, value) => setFilter(value)}
                            defaultValue={defaultValue}
                        />,
                    },
                    {
                        name: 'endPaidAt',
                        defaultValue: undefined,
                        // atIndex: 4,
                        render: (setFilter, defaultValue) => <DatePicker
                            style={{width: 200, marginRight: 10}}
                            placeholder="Do daty opłacenia"
                            allowClear={true}
                            onChange={(_, value) => setFilter(value)}
                            defaultValue={defaultValue}
                        />,
                    }
                ]}
            />}
        >
            <List
                name="orders"
                fields={['order_index', 'sign', 'document', 'customer_short_name', 'invoice_number', 'invoice_amount', 'received', 'paid', 'order_creation_date', 'order_payment_method', 'order_status', 'order_workshop_status', 'order_deadline']}
                render={{
                    order_index: (index, product) => <Link to={`/orders/${product.order_id}`}>{index}</Link>,
                    notes: (notes, product) => <NotesInput defaultValue={notes} tool={product} />,
                    order_payment_method: (notes, method) => <PaymentMethod value={notes} />,
                    order_status: (status) => <OrderStatus value={status} />,
                    order_workshop_status: (status) => <OrderWorkshopStatus value={status} />,
                    order_deadline: (deadline, product) => deadline ? moment(deadline).format('DD-MM-YYYY') : '-',
                    order_creation_date: (deadline, product) => moment(deadline).format('DD-MM-YYYY'),
                    sign: (_, document) => document.signature ? <img src={`${config.API_URL+document.signature.path}`} width={100}/> : <Button onClick={() => handleSign(document)}>Podpisz</Button>,
                    received: (value, document) => <DocumentStatus status={parseInt(value)} date={document.received_at} onToggle={(value) => axios.post('/api/commands/change-document-received-status', {document_id: document.id, received: value})} />,
                    paid: (value, document) => <DocumentStatus status={parseInt(value)} date={document.paid_at} onToggle={(value) => axios.post('/api/commands/change-document-paid-status', {document_id: document.id, paid: value})} />,
                    document: (value, document) => <PdfButtons document={document} />
                }}
                scroll={{x: 'max-content'}}
                fixed={{
                    order_index: 'left'
                }}
                labels={{
                    order_index: 'Id zamówienia',
                    order_deadline: 'Planowany termin realizacji',
                    order_payment_method: 'Sposób płatności',
                    order_creation_date: 'Data utworzenia',
                    order_id: 'Identyfikator zamówienia',
                    customer_short_name: 'Klient',
                    order_status: 'Status',
                    order_workshop_status: 'Status warsztatowy',
                    sign: 'Podpis',
                    invoice_number: 'Numer faktury',
                    invoice_amount: 'Kwota faktury',
                    received: 'Odebrano',
                    paid: 'Opłacone',
                    document: 'Dokument odbioru'
                }}
                data={data}
                page={page}
                lastPage={lastPage}
                onChangePage={page => fetchProducts(page, getFilters())}
                loading={loading}
                hideActions={true}
                expandable={{
                    expandedRowRender: record => <DocumentProducts data={record.products} />,
                    rowExpandable: record => record.name !== 'Not Expandable',
                }}
                rowKey="id"
            />

            <SignDocumentModal
                title="Podpisz dokument"
                id={signModalUuid}
                onOk={() => null}
                onCancel={() => setSignModalUuid(false)}
                destroyOnClose={true}
                afterClose={() => fetchProducts(page, getFilters())}
                width={650}
                products={products}
            />
        </Layout>
    )
}

export default requiredAuth(Pickup);
