import React, {useState} from "react";
import {Button, Col, Form, Row, Input, notification} from "antd";
import { v4 as uuidv4 } from 'uuid';
import axios from 'utils/axios';
import history from 'utils/history';
import RolesSelect from "components/roles-select";

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export default function Category(props) {
    const {data} = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        setLoading(true);

        if (data) {
            axios
                .post('/api/commands/update-product-category', {
                    productCategoryId: data.id,
                    name: values.name,
                })
                .then(response => {
                    notification['success']({
                        message: 'Dane zostały zapisane!',
                    });
                    setLoading(false);
                })
                .catch(error => console.log(error))
            ;
        } else {
            const payload = {
                productCategoryId: uuidv4(),
                name: values.name,
            };

            axios
                .post('/api/commands/create-product-category', payload)
                .then(response => {
                    history.push(`/categories/${payload.productCategoryId}`);
                })
                .catch(error => console.log(error))
            ;
        }
    };

    return (
        <Row>
            <Col span={12} push={6}>
                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                    initialValues={data}
                >
                    <Form.Item name="name" label="Nazwa kategorii" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {data ? 'Zapisz' : 'Utwórz kategorię'}
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
