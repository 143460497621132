import React from 'react';
import {Select} from "antd";
import OfflineContext from "offline/context";

class ProductDefinitionSelect extends React.Component {
    static contextType = OfflineContext;

    render() {
        const definitions = this.context.definitions;
        const definitionsKeys = Object.keys(definitions);

        return (
            <Select
                placeholder="Wybierz produkt"
                loading={definitionsKeys.length === 0}
                filterOption={true}
                {...this.props}
            >
                {definitionsKeys.map(key => <Select.OptGroup label={key}>{definitions[key].map(definition => <Select.Option value={definition.id}>{definition.name}</Select.Option>)}</Select.OptGroup>)}
            </Select>
        )
    }
}

ProductDefinitionSelect.contextType = OfflineContext;
export default ProductDefinitionSelect;
