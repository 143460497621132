import React from "react";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";
import axios from "utils/axios";

const SignatureWrapper = styled.section`
border: 1px solid rgba(0, 0, 0, 0.7);
margin: 20px 0;
width: 300px;
`;

export default function SignatureInput(props) {
    let ref;

    return (
        <SignatureWrapper>
            <SignatureCanvas
                penColor='green'
                canvasProps={{width: 300, height: 100, className: 'sigCanvas'}}
                ref={r => ref=r}
                onEnd={() => {
                    const data = new FormData();
                    ref.getCanvas().toBlob(blob => {
                        data.append('file', blob);

                        axios
                            .post('/api/upload', data)
                            .then(response => {
                                props.onChange && props.onChange(response.data);
                            })
                        ;
                    });
                }}
            />
        </SignatureWrapper>
    );
}
