import moment from "moment";

export function compareStrings(a, b, key) {
    if (a[key] < b[key]) {
        return -1;
    } else if (a[key] > b[key]) {
        return 1;
    }

    return 0;
}

export function compareDates(a, b, key) {
    if (moment(a[key]) < moment(b[key])) {
        return -1;
    } else if (moment(a[key]) > moment(b[key])) {
        return 1;
    }

    return 0;
}
