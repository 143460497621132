import React, {useEffect, useState} from 'react';
import {Input, Space, notification} from 'antd';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import axios from 'utils/axios';
import {Button} from "antd";
import DiameterSelect from "../components/diameter-select";
import store from "../utils/store";
import {appActions} from "action/app";

function Diameters() {
    const [diameters, setDiameters] = useState(store.getState().app.diameters);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        store.subscribe(() => {
            if (store.getState().app.diameters !== undefined) {
                setDiameters(store.getState().app.diameters);
            }
        });
    }, []);

    const handleSave = () => {
        setLoading(true);
        axios
            .post(`/api/commands/update-configuration-value`, {
                name: 'diameters',
                value: diameters,
            })
            .then(() => {
                setLoading(false);
                notification['success']({
                    message: 'Sukces',
                    description: 'Lista średnic została zapisana.',
                });

            })
            .catch(() => {
                setLoading(false);
            });
        ;
        store.dispatch(appActions.setDiameters(diameters));
    }

    return (
        <Layout>
            <Space direction="vertical" style={{width: '100%'}}>
                <Input.TextArea value={diameters} rows={20} onChange={e => setDiameters(e.target.value)} style={{width: '100%'}}/>
                <Button onClick={handleSave} loading={loading}>Zapisz</Button>
            </Space>
        </Layout>
    )
}

export default requiredAuth(Diameters);
