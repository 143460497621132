import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import store from "../utils/store";

function explode(diameters) {
    if (diameters) {
        const arr = diameters.replaceAll(/\n+/g, ',')
            .replaceAll(/\s+/g, '')
            .replaceAll(/\n|/g, '')
            .replaceAll(/\,+/g, ',')
            .split(',')
        return arr.map((v) => ({
            value: v,
            label: v
        }));
    }

    return [];
}

const DiameterSelect = (props) => {
    const [diameters, setDiameters] = useState(explode(store.getState().app.diameters));

    useEffect(() => {
        store.subscribe(() => {
            if (store.getState().app.diameters !== undefined) {
                setDiameters(explode(store.getState().app.diameters));
            }
        });
    }, []);

    return (
        <Select style={{width: 200}} showSearch={true} {...props} options={diameters} />
    )
}

export default DiameterSelect;
