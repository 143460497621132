import React, {useState}  from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import WorkerForm from 'forms/worker';
import {useParams} from "react-router-dom";
import axios from 'utils/axios';
import {Skeleton} from "antd";

function WorkerDetails() {
    const {id} = useParams();
    const [user, setUser] = useState(null);

    if (!user) {
        axios
            .get(`/api/query/get-user?userId=${id}`)
            .then(response => setUser(response.data))
        ;

        return (
            <Layout>
                <Skeleton />
            </Layout>
        );
    }

    return (
        <Layout>
            <WorkerForm data={user} />
        </Layout>
    )
}

export default requiredAuth(WorkerDetails);
