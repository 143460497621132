import React, {useState} from "react";
import { LoadingOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import {Input} from "antd";
import axios from "utils/axios";

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            updating: false
        };

        this.changeNotesDelay = () => null;
    }

    changeNotes(tool, value) {
        clearTimeout(this.changeNotesDelay);
        this.changeNotesDelay = setTimeout(() => {
            this.setState({
                updating: true
            });
            axios
                .post('/api/commands/change-product-additional-notes', {
                    product_id: tool.id,
                    additional_notes: value
                })
                .then(response => this.setState({updating: false}))
                .catch(() => this.setState({updating: false}))
            ;
        }, 500);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.defaultValue !== this.props.defaultValue) {
            this.setState({
                loading: true
            });
            setTimeout(() => this.setState({loading: false}), 200);
        }
    }

    render() {
        if (this.state.loading) {
            return <span>Loading...</span>;
        }

        return (
            <Input
                defaultValue={this.props.defaultValue}
                suffix={this.state.updating ? <LoadingOutlined /> : <CheckCircleTwoTone twoToneColor="#52c41a" />}
                onChange={(e) => this.changeNotes(this.props.tool, e.target.value)}
            />
        )
    }
}
