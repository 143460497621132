import React from 'react';
import {Select} from "antd";
import OfflineContext from "offline/context";

class CustomersSelect extends React.Component {
    static contextType = OfflineContext;

    render() {
        const customers = this.context.customers;

        return (
            <Select
                placeholder="Wybierz klienta"
                loading={customers.length === 0}
                showSearch
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                {...this.props}
                onChange={value => {
                    const customer = customers.find(c => c.id === value);

                    this.props.onChange && this.props.onChange(value);
                    this.props.onChoose && this.props.onChoose(customer);
                }}
            >
                {customers.map(customer => <Select.Option key={`customer-${customer.id}`} value={customer.id}>{`${customer.short_name} (${customer.name})`}</Select.Option>)}
            </Select>
        )
    }
}

CustomersSelect.contextType = OfflineContext;
export default CustomersSelect;
