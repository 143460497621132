import React, {useState} from 'react';
import {Select} from "antd";
import axios from 'utils/axios';

export default function CategorySelect(props) {
    const [definitions, setDefinitions] = useState([]);

    if (definitions.length === 0) {
        axios
            .get('/api/query/all-product-categories')
            .then(response => setDefinitions(response.data));
    }

    return (
        <Select
            placeholder="Wybierz kategorie"
            loading={definitions.length === 0}
            {...props}
        >
            {definitions.map(definition => <Select.Option key={`def-${definition.id}`} value={definition.id}>{definition.name}</Select.Option>)}
        </Select>
    )
}
