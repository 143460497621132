import React, {useState}  from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import RegionForm from 'forms/region';
import {useParams} from "react-router-dom";
import axios from 'utils/axios';
import {Skeleton} from "antd";

function RegionDetails() {
    const {id} = useParams();
    const [region, setRegion] = useState(null);

    if (!region) {
        axios
            .get(`/api/query/get-region?regionId=${id}`)
            .then(response => setRegion(response.data))
        ;

        return (
            <Layout>
                <Skeleton />
            </Layout>
        );
    }

    return (
        <Layout>
            <RegionForm data={region} />
        </Layout>
    )
}

export default requiredAuth(RegionDetails);
