import React, {useState}  from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import CategoryForm from 'forms/category';
import {useParams} from "react-router-dom";
import axios from 'utils/axios';
import {Skeleton} from "antd";

function CategoryDetails() {
    const {id} = useParams();
    const [category, setCategory] = useState(null);

    if (!category) {
        axios
            .get(`/api/query/get-product-category?productCategoryId=${id}`)
            .then(response => setCategory(response.data))
        ;

        return (
            <Layout>
                <Skeleton />
            </Layout>
        );
    }

    return (
        <Layout>
            <CategoryForm data={category} />
        </Layout>
    )
}

export default requiredAuth(CategoryDetails);
