import React  from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import WorkerForm from 'forms/worker';

function CreateWorker() {
    return (
        <Layout>
            <WorkerForm />
        </Layout>
    )
}

export default requiredAuth(CreateWorker);
