import {message} from "antd";
import axios from "utils/axios";
import config from "config";

export const orderUtils = {
    downloadPZPdf,
    sendPZPdf,
    downloadWZPdf,
    sendWZPdf,
};

function handleSendingException(error) {
    if (error.errorCode === 10202) {
        message.error('Klient nie ma przypisanego adresu e-mail');
    }

    if (error.errorCode === 10203) {
        message.error('Klient nie wyraził zgody na otrzymywanie maili');
    }
}

function downloadPZPdf(id, success, error) {
    axios
        .get(`/api/query/generate-order-pdf?orderId=${id}`)
        .then(response => {
            success && success(response);
            window.open(`${config.API_URL}/get-order-pdf?id=${response.data.id}`,'_blank');
        })
        .catch((response) => error && error(response))
    ;
}

function sendPZPdf(id, success, error) {
    axios
        .post(`/api/commands/send-order-pdf`, {
            orderId: id
        })
        .then(response => {
            success && success(response);
            message.success('E-mail został wysłany');
        })
        .catch(response => {
            error && error(response);
            handleSendingException(response.response.data);
        })
    ;
}


function downloadWZPdf(id, success, error) {
    axios
        .get(`/api/query/generate-document-pdf?documentId=${id}`)
        .then(response => {
            success && success(response);
            window.open(`${config.API_URL}/get-order-pdf?id=${response.data.id}`,'_blank');
        })
        .catch((response) => error && error(response))
    ;
}

function sendWZPdf(id, success, error) {
    axios
        .post(`/api/commands/send-document-pdf`, {
            documentId: id
        })
        .then(response => {
            success && success(response);
            message.success('E-mail został wysłany');
        })
        .catch(response => {
            error && error(response);
            handleSendingException(response.response.data);
        })
    ;
}
