export default function app(state = {__initialized__: false}, action) {
    switch (action.type) {
        case 'INITIALIZE':
            return {
                ...state,
                __initialized__: true,
                data: action.data
            }

        case 'FETCH_DIAMETERS':
            return {
                ...state,
                diameters: action.data
            };

        default:
            return state;
    }
}
