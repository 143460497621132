import React, {useState}  from 'react';
import requiredNoAuth from "decorators/required-no-auth";
import styled from 'styled-components';
import {Form, Input, Button, Alert} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import config from 'config';
import axios from 'utils/axios';
import qs from 'qs';
import store from 'utils/store';
import {appActions} from "action/app";
import history from "utils/history";
import fundersLogo from "resources/funders.png";

const Wrapper = styled.section`
width: 100vw;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

const Content = styled.div`
max-width: 400px;
width: 100%;
display: block;
`;

const FundedSection = styled.div`
max-width: 800px;
width: 100%;
display: block;
text-align: center;
`;

const validateMessages = {
    required: 'To pole jest wymagane!',
    types: {
        email: 'Adres e-mail jest niepoprawny',
    },
};


function Login() {
    const [hasWrongCredentials, setWrongCredentials] = useState(false);
    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        setLoading(true);

        axios
            .post('/oauth/v2/token', qs.stringify({
                'client_id': config.APP_CLIENT_ID,
                'client_secret': config.APP_CLIENT_SECRET,
                'grant_type': 'password',
                'username': values.auth.email,
                'password': values.auth.password
            }))
            .then(response => {
                const token = response.data;
                const credentials = {
                    token,
                    remember: true,
                };

                localStorage.setItem('auth_credentials', JSON.stringify(credentials));
                return response.data;
            })
            .then(data => {
                store.dispatch(appActions.initialize(() => {
                    history.push('/');
                }));
            })
            .catch(error => {
                setWrongCredentials(true);
                setLoading(false);
            })
        ;
    };

    return (
        <Wrapper>
            <Content>
                {
                    hasWrongCredentials ? <Alert message="Adres e-mail lub hasło są niepoprawne!" type="error" showIcon /> : null
                }

                <Form name="nest-messages" onFinish={onFinish} validateMessages={validateMessages} style={{marginTop: 20}}>
                    <Form.Item name={['auth', 'email']} rules={[{ type: 'email' }]}>
                        <Input
                            type="email"
                            size="large"
                            prefix={<UserOutlined style={{opacity: 0.5}}/>}
                            placeholder="Adres e-mail"
                        />
                    </Form.Item>
                    <Form.Item name={['auth', 'password']}>
                        <Input.Password
                            size="large"
                            prefix={<LockOutlined style={{opacity: 0.5}}/>}
                            placeholder="Hasło"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" size="large" htmlType="submit" style={{width: '100%'}} loading={loading}>
                            Zaloguj się
                        </Button>
                    </Form.Item>
                </Form>
            </Content>

            <FundedSection>
                <p style={{fontWeight: 'bold'}}>PROGRAM GREENProManager ZOSTAŁ SFINANSOWANY ZE ŚRODKÓW:</p>
                <img src={fundersLogo} style={{maxWidth: '100%'}} /><br/>
            </FundedSection>
        </Wrapper>
    )
}

export default requiredNoAuth(Login);
