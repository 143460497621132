import React  from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import ProductForm from 'forms/product';

function CreateProduct() {
    return (
        <Layout>
            <ProductForm />
        </Layout>
    )
}

export default requiredAuth(CreateProduct);
