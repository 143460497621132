import React  from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import CategoryForm from 'forms/category';

function CreateCategory() {
    return (
        <Layout>
            <CategoryForm />
        </Layout>
    )
}

export default requiredAuth(CreateCategory);
