import React  from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import RegionForm from 'forms/region';

function CreateRegion() {
    return (
        <Layout>
            <RegionForm />
        </Layout>
    )
}

export default requiredAuth(CreateRegion);
