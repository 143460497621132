import React from 'react';

export default function OrderStatus(props) {
    switch (props.value) {
        case 'nowy':
            return <span>Nowy</span>;

        case 'in_progress':
            return <span>W trakcie realizacji</span>;

        case 'received':
            return <span>Odebrano</span>;

        case 'partially_completed':
            return <span>Częściowo zrealizowane</span>;

        default:
            return <span>Zrealizowane</span>
    }
}
