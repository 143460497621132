import React, {useEffect, useState} from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import config from 'config';
import axios from 'utils/axios';
import List from 'components/list';
import {Button, Switch} from "antd";
import ProductStatusSelect from "components/product-status-select";
import OrderStatus from "components/order-status";
import AdditionalNotesInput from "components/additional-notes-input";
import ProductStatus from "components/order/ProductStatus";
import moment from "moment";
import Filters, {convertToQueryString, getFilters} from "utils/filters";
import ProductCategorySelect from "../components/product-category-select";
import {useQueryParam} from "../utils/use-query-param";
import OrderItemPhoto from "../components/order/order-item-photos";

function Sharping() {
    const [data, setData] = useState([]);
    const [page, setPage] = useQueryParam('page', 1);
    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [detailedView, setDetailedView] = useState(false);
    const [printUrl, setPrintUrl] = useState('');

    useEffect(() => {
        fetchProducts(page, getFilters());
    }, []);

    function fetchProducts(page, filters = {}) {
        setLoading(true);
        setPage(page);

        const credentials = JSON.parse(localStorage.getItem('auth_credentials'));

        setPrintUrl(`${config.API_URL}/api/print-products-by-filters?page=-1${convertToQueryString(filters)}&access_token=${credentials.token.access_token}`);
        axios
            .get(`/api/query/get-products-by-filters?page=${page+convertToQueryString(filters)}`)
            .then(response => {
                setData(response.data.data);
                setLastPage(response.data.last_page);
                setLoading(false);
            })
        ;
    }

    function changeStatus(product, value) {
        axios
            .post('/api/commands/change-product-status', {
                product_id: product.id,
                status: value
            })
        ;
    }

    return (
        <Layout
            headerRightSide={() => <>
                <Button type="primary" onClick={() => window.open(printUrl)} style={{marginRight: 10}}>
                    Widok do wydruku
                </Button>

                <div style={{marginRight: 20, display: 'inline-block'}}>
                    <label style={{marginRight: 10}}>Widok szczegółowy</label>
                    <Switch checked={detailedView} onChange={(checked) => setDetailedView(checked)} />
                </div>

                <Filters
                    onChange={filters => fetchProducts(1, filters)}
                    renderBefore={[
                        {
                            name: 'productStatus',
                            defaultValue: undefined,
                            atIndex: 4,
                            render: (setFilter, defaultValue) => <ProductStatusSelect
                                defaultValue={defaultValue}
                                placeholder="Status narzędzia"
                                allowClear={true}
                                style={{width: 200, marginRight: 10}}
                                onChange={value => setFilter(value)}
                            />
                        },
                        {
                            name: 'categoryId',
                            defaultValue: undefined,
                            atIndex: 5,
                            render: (setFilter, defaultValue) => <ProductCategorySelect
                                defaultValue={defaultValue}
                                allowClear={true}
                                style={{width: 200, marginRight: 10}}
                                onChange={value => setFilter(value)}
                            />
                        }
                    ]}
                />
            </>}
        >
            <List
                name="sharping"
                fields={
                    detailedView ?
                        ['order_index', 'customer_short_name', 'name', 'hole', 'diameter', 'quantity', 'status', 'order_creation_date', 'order_deadline', 'notes', 'additional_notes', 'photo'] :
                        ['order_index', 'customer_short_name', 'name', 'quantity', 'status', 'order_creation_date', 'order_deadline', 'notes', 'additional_notes']
                }
                render={{
                    status: (value, product) => product.document_id ? <ProductStatus value={product.status} /> : <ProductStatusSelect defaultValue={product.status} onChange={value => changeStatus(product, value)} style={{width: 100}}/>,
                    order_status: (notes, product) => <OrderStatus value={product.order_status} />,
                    notes: (notes, product) => <span>{product.notes}</span>,
                    additional_notes: (notes, product) => product.document_id ? <span>{product.additional_notes}</span> : <AdditionalNotesInput defaultValue={notes} tool={product} />,
                    order_creation_date: (creation_date, product) => creation_date ? moment(creation_date).format('DD-MM-YYYY') : '-',
                    order_deadline: (deadline, product) => deadline ? moment(deadline).format('DD-MM-YYYY') : '-',
                    photo: (photo, tool) => <OrderItemPhoto
                        classNameWrapper="order-item-photos-wrapper--auto-width"
                        photos={tool.photo ? tool.photo : []}
                        append={true}
                        productId={tool.id}
                    />
                }}
                fixed={{
                    order_index: 'left'
                }}
                scroll={detailedView ? {x: 1600} : undefined}
                labels={{
                    name: 'Narzędzie',
                    hole: 'Otwór',
                    diameter: 'Średnica',
                    order_id: 'Identyfikator zamówienia',
                    customer_short_name: 'Klient',
                    status: 'Status',
                    order_status: 'Status zamówienia',
                    notes: 'Uwagi od handlowców',
                    photo: 'Zdjęcie',
                    additional_notes: 'Uwagi od warsztatu',
                    quantity: 'Ilość',
                    order_index: 'Id zamówienia',
                    order_deadline: 'Termin realizacji',
                    order_creation_date: 'Data utworzenia zamówienia',
                }}
                hideActions={true}
                data={data}
                page={page}
                lastPage={lastPage}
                onChangePage={page => fetchProducts(page, getFilters())}
                loading={loading}
            />
        </Layout>
    )
}

export default requiredAuth(Sharping);
