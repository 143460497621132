import React, {useEffect, useState} from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import CustomerForm from 'forms/customer';
import {useParams} from "react-router-dom";
import axios from 'utils/axios';
import {Skeleton} from "antd";

function CustomerDetails() {
    const {id} = useParams();
    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        axios
            .get(`/api/query/get-customer?customerId=${id}`)
            .then(response => setCustomer(response.data))
        ;
    }, []);

    if (!customer) {
        return (
            <Layout>
                <Skeleton />
            </Layout>
        );
    }

    return (
        <Layout>
            <CustomerForm data={customer} />
        </Layout>
    )
}

export default requiredAuth(CustomerDetails);
