import React from 'react';
import {Badge} from "antd";

export default function status(props) {
    switch (props.value) {
        case 'in_progress':
            return <Badge status="warning" text="W trakcie realizacji" />;

        case 'completed':
            return <Badge status="success" text="Zrealizowane" />;


        case 'partially_completed':
            return <Badge status="success" text="Częściowo zrealizowane" />;

        case 'received':
            return <Badge status="error" text="Odebrano" />;

        default:
            return <Badge status="processing" text="Nowe" />;
    }
}
