import React, {useEffect, useState} from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import axios from 'utils/axios';

import List from 'components/list';
import {compareStrings} from "utils/table";
import {Button, Input, Space, Popconfirm} from "antd";
import {Link} from "react-router-dom";
import {useQueryParam} from "../utils/use-query-param";
import {throttle} from "../utils/throttle";

function Customers() {
    const [data, setData] = useState([]);
    const [search, setSearch, clearSearch] = useQueryParam('search');

    useEffect(fetchCustomers, []);

    function fetchCustomers(search) {
        axios
            .get('/api/query/all-customers', {params: {search}})
            .then(response => setData(response.data))
        ;
    }

    function handleDelete(customer) {
        axios
            .post('/api/commands/customer-archive', {
                customerId: customer.id,
            })
            .then(response => {
                const _data = [...data];
                const missingIndex = _data.findIndex(c => c.id === customer.id);
                _data.splice(missingIndex, 1);
                setData(_data);
            })
        ;
    }

    function handleSearch(search) {
        setSearch(search.target.value);
        throttle(fetchCustomers, 1000, search.target.value);
    }

    return (
        <Layout
            headerRightSide={() => (
                <Space>
                    <Link to="/customers/new">
                        <Button type="primary">
                            Dodaj klienta
                        </Button>
                    </Link>

                    <Input onChange={handleSearch} defaultValue={search} placeholder="Wyszukaj" />
                </Space>
            )}
        >
            <List
                name="customers"
                fields={['name', 'region', 'phone_number', 'address', 'actions']}
                hideActions={true}
                render={{
                    address: (value, customer) => {
                        return (
                            <>
                                {customer.address_line_1}<br/>
                                {customer.address_line_2 ? customer.address_line_2 : null}
                                {customer.address_line_2 ? <br/> : null}
                                {`${customer.postcode} ${customer.city}`}<br/>
                                {customer.country}
                            </>
                        )
                    },
                    name: (value, customer) => {
                        return (
                            <>
                                {customer.short_name}<br/>
                                {customer.name}
                            </>
                        )
                    },
                    actions: (value, customer) => (
                        <Space>
                            <Link to={`/customers/${customer.id}`} style={{marginRight: 10}}>
                                <Button>Szczegóły</Button>
                            </Link>
                            <Popconfirm title="Na pewno chcesz usunąć?" onConfirm={() => handleDelete(customer)}>
                                <Button type="danger">
                                    Usuń
                                </Button>
                            </Popconfirm>
                        </Space>
                    )
                }}
                sort={{
                    name: {
                        compare: (a, b) => compareStrings(a, b, 'short_name'),
                    }
                }}
                labels={{
                    name: 'Klient',
                    address: 'Adres',
                    phone_number: 'Numer Telefonu',
                    region: 'Region',
                    actions: 'Akcje',
                }}
                alignRight={['actions']}
                data={data}
            />
        </Layout>
    )
}

export default requiredAuth(Customers);
