export function useQueryParam(param, defaultValue) {
    const setParam = (value) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set(param, value);
        window.history.replaceState(null, null, "?"+urlParams.toString());
    }

    const deleteParam = () => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete(param);
        window.history.replaceState(null, null, "?"+urlParams.toString());
    }

    const urlParams = new URLSearchParams(window.location.search);
    return [urlParams.get(param) ? urlParams.get(param) : defaultValue, setParam, deleteParam];
}
