import React from 'react';
import {Badge, Select} from "antd";

export default function UserRole(props) {
    switch (props.role) {
        case 'admin':
            return <Badge status="warning" text="Admin" />;

        case 'sharper':
            return <Badge status="success" text="Pracownik" />;

        default:
            return <Badge status="processing" text="Handlowiec" />;
    }
}
