import React, {useState} from "react";
import {Button, Col, Form, Row, Input, notification} from "antd";
import { v4 as uuidv4 } from 'uuid';
import axios from 'utils/axios';
import history from 'utils/history';
import RolesSelect from "components/roles-select";
import CategorySelect from "components/category-select";

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export default function Product(props) {
    const {data} = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        setLoading(true);

        if (data) {
            axios
                .post('/api/commands/update-product-definition', {
                    productDefinitionId: data.id,
                    name: values.name,
                    categoryId: values.category_id,
                })
                .then(response => {
                    notification['success']({
                        message: 'Dane zostały zapisane!',
                    });
                    setLoading(false);
                })
                .catch(error => console.log(error))
            ;
        } else {
            const payload = {
                productDefinitionId: uuidv4(),
                name: values.name,
                categoryId: values.category_id,
            };

            axios
                .post('/api/commands/create-product-definition', payload)
                .then(response => {
                    history.push(`/products/${payload.productDefinitionId}`);
                })
                .catch(error => console.log(error))
            ;
        }
    };

    return (
        <Row>
            <Col span={12} push={6}>
                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                    initialValues={data}
                >
                    <Form.Item name="name" label="Nazwa produktu" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="category_id" label="Kategoria" rules={[{ required: true }]}>
                        <CategorySelect />
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {data ? 'Zapisz' : 'Utwórz produkt'}
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
