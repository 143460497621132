import React from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import axios, {getAuthCredentials} from 'utils/axios';
import List from 'components/list';
import {Tabs, DatePicker, Table, Button} from "antd";
import CustomersSelect from "components/customers-select";
import moment from 'moment';
import PaymentMethod from "components/payment-method";
import OrderStatus from "components/order/Status";
import NotesInput from "components/notes-input";
import {compareStrings} from "utils/table";
import ProductStatus from "../components/order/ProductStatus";
import config from 'config';

const {TabPane} = Tabs;
const filters = {
    startDate: undefined,
    endDate: undefined,
    pickupStartDate: undefined,
    pickupEndDate: undefined,
    clientId: undefined
};

export class Reports extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            dataSimple: [],
            page: 1,
            lastPage: 1,
            loading: false,
            loadingSimple: false,
            activeTab: "2",
            filters: filters,
        };
    }

    componentDidMount() {
        this.fetchProducts(1, filters);
    }

    fetchProducts(page, filters = {}) {
        this.setState({
            loading: true,
            loadingSimple: true,
            page,
            filters
        });

        const filtersKeys = Object.keys(filters);
        let filtersQueryString = '';

        for (let i = 0, count = filtersKeys.length; i <= count; i++) {
            if (filters[filtersKeys[i]]) {
                filtersQueryString += '&' + filtersKeys[i] + '=' + filters[filtersKeys[i]];
            }
        }

        axios
            .get(`/api/query/get-orders-by-filters?report=1&page=${page + filtersQueryString}`)
            .then(response => {
                this.setState({
                    data: response.data.data,
                    loading: false,
                    lastPage: response.data.last_page
                });
            })
        ;

        axios
            .get(`/api/query/get-products-by-filters?report=1&page=-1${filtersQueryString}`)
            .then(response => {
                this.setState({
                    dataSimple: response.data.data,
                    loadingSimple: false
                });
            })
        ;
    }

    setFilter(filter, value) {
        this.setState({page: 1})
        filters[filter] = value;
        this.fetchProducts(1, filters);
    }

    processSimpleData(data) {
        const newData = [];

        data.forEach(product => {
            const index = newData.findIndex(p => p.definition_id === product.definition_id && p.diameter === product.diameter);

            if (index === -1) {
                newData.push({
                    ...product,
                    quantity: parseInt(product.quantity),
                    notes: product.notes ? [product.notes] : [],
                    additional_notes: product.additional_notes ? [product.additional_notes] : [],
                });
            } else {
                newData[index].quantity += parseInt(product.quantity);

                if (product.notes) {
                    newData[index].notes.push(product.notes);
                }

                if (product.additional_notes) {
                    newData[index].additional_notes.push(product.additional_notes);
                }
            }
        });

        return newData;
    }

    render() {
        const {data, dataSimple, page, lastPage, loading, loadingSimple} = this.state;

        const handlePDF = () => {
            const filters = this.state.filters;
            const filtersKeys = Object.keys(filters);
            let filtersQueryString = '';

            for (let i = 0, count = filtersKeys.length; i <= count; i++) {
                if (filters[filtersKeys[i]]) {
                    filtersQueryString += '&' + filtersKeys[i] + '=' + filters[filtersKeys[i]];
                }
            }

            window.open(`${config.API_URL}/api/generate-report?report=1&page=${page + filtersQueryString}&access_token=${getAuthCredentials().token.access_token}`, '_blank').focus();
        }

        const columns = [
            {
                title: 'Nazwa',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (status, product) => <ProductStatus value={status}/>
            },
            {
                title: 'Otwór',
                dataIndex: 'hole',
                key: 'hole',
            },
            {
                title: 'Średnica',
                dataIndex: 'diameter',
                key: 'diameter',
            },
            {
                title: 'Ilość',
                dataIndex: 'quantity',
                key: 'quantity',
            }
        ];

        return (
            <Layout
                headerRightSide={() => <>
                    <CustomersSelect style={{width: 200, marginRight: 10}} allowClear={true}
                                     onChange={value => this.setFilter('clientId', value)} defaultValue={filters.clientId}/>
                    <DatePicker style={{width: 200, marginRight: 10}} placeholder="Od daty planowanego terminu realizacji" allowClear={true}
                                onChange={(date, dateString) => this.setFilter('startDate', dateString)} defaultValue={filters.startDate ? moment(filters.startDate, 'YYYY-MM-DD') : undefined}/>
                    <DatePicker style={{width: 200, marginRight: 10}} placeholder="Do daty planowanego terminu realizacji" allowClear={true}
                                onChange={(date, dateString) => this.setFilter('endDate', dateString)} defaultValue={filters.endDate ? moment(filters.endDate, 'YYYY-MM-DD') : undefined}/>

                    <DatePicker style={{width: 200, marginRight: 10}} placeholder="Od daty odbioru przez klienta" allowClear={true}
                                onChange={(date, dateString) => this.setFilter('pickupStartDate', dateString)} defaultValue={filters.startDate ? moment(filters.startDate, 'YYYY-MM-DD') : undefined}/>
                    <DatePicker style={{width: 200, marginRight: 10}} placeholder="Do daty odbioru przez klienta" allowClear={true}
                                onChange={(date, dateString) => this.setFilter('pickupEndDate', dateString)} defaultValue={filters.endDate ? moment(filters.endDate, 'YYYY-MM-DD') : undefined}/>

                    <Button onClick={handlePDF}>Export PDF</Button>
                </>}
            >
                <Tabs defaultActiveKey={this.state.activeTab} onChange={activeTab => this.setState({activeTab})}>
                    <TabPane tab="Ogólny" key="1">
                        <List
                            name="sharping"
                            fields={['customer_short_name', 'status', 'payment_method', 'deadline', 'creation_date']}
                            render={{
                                notes: (notes, product) => <NotesInput defaultValue={notes} tool={product}/>,
                                payment_method: (method) => <PaymentMethod value={method}/>,
                                status: (status) => <OrderStatus value={status}/>,
                                deadline: (deadline) => deadline ? moment(deadline).format('DD-MM-YYYY') : '-',
                                creation_date: (deadline) => moment(deadline).format('DD-MM-YYYY')
                            }}
                            labels={{
                                deadline: 'Planowany termin realizacji',
                                payment_method: 'Sposób płatności',
                                creation_date: 'Data utworzenia',
                                order_id: 'Identyfikator zamówienia',
                                customer_short_name: 'Klient',
                                status: 'Status',
                                notes: 'Uwagi',
                            }}
                            hideActions={true}
                            data={data}
                            page={page}
                            lastPage={lastPage}
                            onChangePage={page => this.fetchProducts(page, filters)}
                            loading={loading}
                            rowKey="id"
                            expandable={{
                                expandedRowRender: record => <Table dataSource={record.products} columns={columns}/>,
                                rowExpandable: record => record.products.length > 0
                            }}
                        />
                    </TabPane>
                    <TabPane tab="Szczegółowy" key="2">
                        <List
                            name="sharping"
                            fields={['name', 'hole', 'diameter', 'quantity', 'notes', 'additional_notes']}
                            labels={{
                                name: 'Narzędzie',
                                hole: 'Otwór',
                                diameter: 'Średnica',
                                quantity: 'Ilość',
                                notes: 'Uwagi',
                                additional_notes: 'Uwagi od warsztatu',
                            }}
                            sort={{
                                name: {
                                    compare: (a, b) => compareStrings(a, b, 'name'),
                                },
                                quantity: {
                                    compare: (a, b) => a.quantity - b.quantity,
                                }
                            }}
                            render={{
                                notes: (notes) => {
                                    return notes.map(note => <p>{note}</p>);
                                },
                                additional_notes: (notes) => {
                                    return notes.map(note => <p>{note}</p>);
                                }
                            }}
                            hideActions={true}
                            data={this.processSimpleData(dataSimple)}
                            onChangePage={page => this.fetchProducts(page, filters)}
                            loading={loadingSimple}
                        />
                    </TabPane>
                </Tabs>
            </Layout>
        )
    }
}

export default requiredAuth(Reports);
