import React, {useState} from "react";
import {Button, Col, DatePicker, Form, InputNumber, Popconfirm, Row, Input, notification} from "antd";
import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import CustomersSelect from 'components/customers-select';
import PaymentMethodSelect from "components/payment-method-select";
import ProductDefinitionSelect from "components/product-definition-select";
import UploadPhoto from "components/upload-photo";
import { v4 as uuidv4 } from 'uuid';
import axios from 'utils/axios';
import history from 'utils/history';
import config from "config";
import moment from "moment";
import SignatureInput from "../components/signature-input";
import OrderItemPhoto from "../components/order/order-item-photos";
import DiameterSelect from "../components/diameter-select";

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 24,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 18,
            offset: 8,
        },
    },
};

const ProductRow = styled.section`
display: flex;
flex-direction: row;
margin-bottom: 10px;
`;

export default function Order(props) {
    const {data} = props;
    const [form] = Form.useForm();
    const [products, setProducts] = useState([...Array(5)].map((_, i) => ({id: undefined, quantity: 1, hole: null, diameter: null, notes: null, photo: null})));
    const [processing, setProcessing] = useState(false);

    const onFinish = values => {
        setProcessing(true);
        if (data) {
            axios
                .post('/api/commands/order-update', {
                    orderId: data.order.id,
                    paymentMethod: values.payment_method,
                    deadline: values.deadline,
                })
                .then(response => {
                    setProcessing(false);
                    notification['success']({
                        message: 'Dane zostały zapisane!',
                    });
                })
                .catch(error => console.log(error))
            ;
        } else {
            const payload = {
                orderId: uuidv4(),
                assigneeId: values.assignee_id,
                paymentMethod: values.payment_method,
                deadline: values.deadline,
                photo: values.photo,
                signature: values.signature,
                products,
            };

            localStorage.setItem('last_order', JSON.stringify(payload));

            axios
                .post('/api/commands/order-create', payload)
                .then(response => {
                    setProcessing(false);
                    history.push(`/orders/${payload.orderId}`);
                })
                .catch(error => console.log(error))
            ;
        }
    };

    function addProductRow() {
        setProducts([...products, {id: null, quantity: 1, hole: null, diameter: null, notes: null}]);
    }

    function removeProductRow(index) {
        const p = [...products];
        p.splice(index, 1);
        setProducts(p);
    }

    function changeQuantity(index, value) {
        const p = [...products];
        p[index].quantity = value;
        setProducts(p);
    }

    function changeHole(index, value) {
        const p = [...products];
        p[index].hole = value.target.value;
        setProducts(p);
    }

    function changeDiameter(index, value) {
        const p = [...products];
        p[index].diameter = value;
        setProducts(p);
    }

    function changePhoto(index, value) {
        const p = [...products];
        p[index].photo = value;
        setProducts(p);
    }

    function changeNotes(index, value) {
        const p = [...products];
        p[index].notes = value.target.value;
        setProducts(p);
    }

    function changeProduct(index, value) {
        const p = [...products];
        p[index].id = value;
        setProducts(p);
    }

    const normalizedData = data ? {
        ...data.order,
        deadline: data.order.deadline ? moment(data.order.deadline) : null,
    } : null;

    const handleChangeCustomer = (value) => {
        let deadline = null;

        if (value.default_due_date_days) {
            deadline = moment().add(value.default_due_date_days, 'days')
        }

        form.setFieldsValue({
            payment_method: value.payment_method,
            deadline,
        });
    }

    return (
        <Row>
            <Col span={24}>
                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                    initialValues={normalizedData}
                >
                    {data ? <Form.Item name="order_index" label="Id zamówienia">
                        <span>{data.order.order_index}</span>
                    </Form.Item> : null}

                    {data ? <Form.Item name="creation_date" label="Data odbioru od klienta">
                        <span>{moment(data.order.creation_date).format('DD-MM-YYYY')}</span>
                    </Form.Item> : null}

                    <Form.Item name="assignee_id" label="Klient" rules={[{ required: true }]}>
                        <CustomersSelect disabled={data} onChoose={handleChangeCustomer} />
                    </Form.Item>

                    <Form.Item name="payment_method" label="Rodzaj płatności" rules={[{ required: true }]}>
                        <PaymentMethodSelect />
                    </Form.Item>

                    <Form.Item name="deadline" label="Planowany termin oddania" rules={[{ required: true }]}>
                        <DatePicker placeholder="Wybierz termin" />
                    </Form.Item>

                    {
                        data ? (
                            data.order.signature ? (
                                <Form.Item name="signature" label="Podpis">
                                    <img src={`${config.API_URL+data.order.signature.path}`}/>
                                </Form.Item>
                            ) : '-'
                        ) : (
                            <Form.Item name="signature" label="Podpis" rules={[{ required: true }]}>
                                <SignatureInput />
                            </Form.Item>
                        )
                    }

                    {
                        !data ? (
                            <div style={{display: 'flex', width: '100%', flexDirection: 'column'}}>
                                {
                                    products.map((product, i) => (
                                        <ProductRow style={{display: 'flex'}}>
                                            <ProductDefinitionSelect size="large" onChange={value => changeProduct(i, value)} style={{minWidth: 300, maxWidth: 500}} defaultValue={product.id} />

                                            <DiameterSelect placeholder="Średnica" value={product.diameter} style={{minWidth: 100, maxWidth: 200, marginLeft: 10}} onChange={value => changeDiameter(i, value)} size="large" />
                                            <Input placeholder="Otwór" value={product.hole} style={{minWidth: 100, maxWidth: 200, marginLeft: 10}} onChange={value => changeHole(i, value)} />
                                            <InputNumber size="large" min={1} max={100000} value={product.quantity} style={{minWidth: 100, maxWidth: 200, marginLeft: 10, marginRight: 10}} onChange={value => changeQuantity(i, value)} />
                                            <Input placeholder="Uwagi" value={product.notes} style={{marginRight: 10}} onChange={value => changeNotes(i, value)} />

                                            <OrderItemPhoto
                                                photos={product.photos ? product.photos : []}
                                                onChange={data => changePhoto(i, data)}
                                            />

                                            <Popconfirm title="Na pewno chcesz usunąć" onConfirm={() => removeProductRow(i)}>
                                                <Button type="danger" size="large">
                                                    <DeleteOutlined />
                                                </Button>
                                            </Popconfirm>
                                        </ProductRow>
                                    ))
                                }
                                <Button size="small" onClick={addProductRow} disabled={processing} style={{width: 200}}>Dodaj produkt</Button>
                            </div>
                        ) : null
                    }

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" disabled={processing}>
                            {data ? 'Zapisz' : 'Utwórz zamówienie'}
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
