import React, {useState} from 'react';
import {Table} from 'antd';
import axios from 'utils/axios';
import NotesInput from "components/notes-input";
import AdditionalNotesInput from "components/additional-notes-input";
import Photo from "components/photo";
import ProductStatus from "components/order/ProductStatus";
import OrderItemPhoto from "./order/order-item-photos";

export default function ToolsList(props) {

    const [data, setData] = useState(props.data.map(tool => {
        tool.editable = false;
        return tool;
    }));

    function toggleEditable(id, value = null) {
        const _data = data.map((tool) => {
            if (tool.id === id) {
                return {
                    ...tool,
                    editable: !tool.editable,
                    status: value ? value : tool.status
                };
            }

            return tool;
        });

        setData(_data);
    }

    const columns = [
        {
            title: 'Nazwa narzędzia',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <ProductStatus value={status} />
        },
        {
            title: 'Otwór',
            dataIndex: 'hole',
            key: 'hole'
        },
        {
            title: 'Średnica',
            dataIndex: 'diameter',
            key: 'diameter'
        },
        {
            title: 'Ilość',
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: 'Uwagi',
            dataIndex: 'notes',
            key: 'notes',
            align: 'right',
            render: (notes, tool) => tool.order_status === 'completed' ? <span>{notes}</span> : <NotesInput defaultValue={notes} tool={tool} />
        },
        {
            title: 'Uwagi od warsztatu',
            dataIndex: 'additional_notes',
            key: 'additional_notes',
            align: 'right',
            render: (notes, tool) => tool.order_status === 'completed' ? <span>{notes}</span> : <AdditionalNotesInput defaultValue={notes} tool={tool} />
        },
        {
            title: 'Zdjęcie',
            dataIndex: 'photo',
            key: 'photo',
            align: 'right',
            // render: (photo, tool) => tool.photo ? <Photo source={tool.photo.path} /> : '-'
            render: (photo, tool) => <OrderItemPhoto
                classNameWrapper="order-item-photos-wrapper--auto-width"
                photos={tool.photo ? tool.photo : []}
                append={true}
                productId={tool.id}
            />
        },
    ];

    return <Table columns={columns} dataSource={data} size="small" />
}
