import React, {useState} from "react";
import {Button, Col, Form, Row, Input, Select, notification} from "antd";
import { v4 as uuidv4 } from 'uuid';
import axios from 'utils/axios';
import history from 'utils/history';
import UsersSelect from "components/users-select";
import PaymentMethodSelect from "components/payment-method-select";
import RegionSelect from "components/region-select";

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export default function Customer(props) {
    const {data} = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        setLoading(true);

        if (data) {
            axios
                .post('/api/commands/customer-update', {
                    customerId: data.id,
                    shortName: values.short_name,
                    phoneNumber: values.phone_number,
                    name: values.name,
                    email: values.email,
                    region: values.region,
                    sendEmail: parseInt(values.send_email) ? 1 : 0,
                    addressLine1: values.address_line_1,
                    postcode: values.postcode,
                    city: values.city,
                    country: values.country,
                    managerId: values.manager_id,
                    paymentMethod: values.payment_method,
                    defaultDueDateDays: values.default_due_date_days ? parseInt(values.default_due_date_days) : null,
                })
                .then(response => {
                    notification['success']({
                        message: 'Dane zostały zapisane!',
                    });
                    setLoading(false);
                })
                .catch(error => setLoading(false))
            ;
        } else {
            const payload = {
                customerId: uuidv4(),
                shortName: values.short_name,
                phoneNumber: values.phone_number,
                name: values.name,
                sendEmail: parseInt(values.send_email) ? 1 : 0,
                email: values.email,
                region: values.region,
                addressLine1: values.address_line_1,
                postcode: values.postcode,
                city: values.city,
                country: values.country,
                managerId: values.manager_id,
                paymentMethod: values.payment_method,
                defaultDueDateDays: values.default_due_date_days ? parseInt(values.default_due_date_days) : null,
            };

            axios
                .post('/api/commands/customer-create', payload)
                .then(response => {
                    history.push(`/customers/${payload.customerId}`);
                })
                .catch(error => setLoading(false))
            ;
        }
    };

    return (
        <Row>
            <Col span={12} push={6}>
                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                    initialValues={data}
                >
                    <Form.Item name="short_name" label="Skrócona Nazwa klienta" rules={[{ required: true}, {max: 25, message: 'Skrócona nazwa powinna mieć maksymalnie 25 znaków'}]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="phone_number" label="Numer telefonu">
                        <Input />
                    </Form.Item>

                    <Form.Item name="name" label="Nazwa klienta" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="email" label="Adres e-mail">
                        <Input />
                    </Form.Item>

                    <Form.Item name="address_line_1" label="Ulica i numer" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="postcode" label="Kod pocztowy" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="city" label="Nazwa miejscowości" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="country" label="Kraj" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="manager_id" label="Opiekun">
                        <UsersSelect />
                    </Form.Item>

                    <Form.Item name="payment_method" label="Rodzaj płatności">
                        <PaymentMethodSelect />
                    </Form.Item>

                    <Form.Item name="send_email" label="Potwierdzenie zamówienia e-mail">
                        <Select placeholder="Wybierz czy chcesz wysłać maila z potwierdzeniem zamówienia">
                            <Select.Option value={0}>Nie</Select.Option>
                            <Select.Option value={1}>Tak</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="region" label="Region">
                        <RegionSelect />
                    </Form.Item>

                    <Form.Item name="default_due_date_days" label="Domyślny termin realizacji">
                        <Select placeholder="Domyślny termin realizacji" allowClear={true}>
                            <Select.Option value={1}>1 dzień</Select.Option>
                            <Select.Option value={2}>2 dni</Select.Option>
                            <Select.Option value={3}>3 dni</Select.Option>
                            <Select.Option value={4}>4 dni</Select.Option>
                            <Select.Option value={5}>5 dni</Select.Option>
                            <Select.Option value={6}>6 dni</Select.Option>
                            <Select.Option value={7}>7 dni</Select.Option>
                            <Select.Option value={8}>8 dni</Select.Option>
                            <Select.Option value={9}>9 dni</Select.Option>
                            <Select.Option value={10}>10 dni</Select.Option>
                            <Select.Option value={11}>11 dni</Select.Option>
                            <Select.Option value={12}>12 dni</Select.Option>
                            <Select.Option value={13}>13 dni</Select.Option>
                            <Select.Option value={14}>14 dni</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {data ? 'Zapisz' : 'Utwórz klienta'}
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
