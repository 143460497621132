import React from 'react';
import {Select} from "antd";

export default class OrderWorkshopStatusSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.defaultValue !== this.props.defaultValue) {
            this.setState({
                loading: true
            });
            setTimeout(() => this.setState({loading: false}), 200);
        }
    }

    render() {
        const {props} = this;

        const options = [{
            value: 'new',
            text: 'Nowy'
        },{
            value: 'in_progress',
            text: 'W trakcie realizacji'
        },{
            value: 'completed',
            text: 'Zrealizowane'
        }];

        if (props.before) {
            props.before.reverse().forEach(option => options.unshift(option));
        }

        if (props.after) {
            props.after.forEach(option => options.push(option));
        }

        if (this.state.loading) {
            return <span>Loading...</span>;
        }

        return (
            <Select {...props}>
                {options.map(option => <Select.Option key={option.value} value={option.value}>{props.prefix ? `${props.prefix}: ${option.text}` : option.text}</Select.Option>)}
            </Select>
        )
    }
}
