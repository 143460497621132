import React from 'react';
import {Select} from "antd";

export default function PaymentMethodSelect(props) {
    return (
        <Select placeholder="Wybierz rodzaj płatności" {...props}>
            <Select.Option value="collective">Zbiorcza FV</Select.Option>
            <Select.Option value="cash">Gotówka FV</Select.Option>
            <Select.Option value="transfer">Przelew FV</Select.Option>
            <Select.Option value="cash_on_delivery">Pobranie</Select.Option>
        </Select>
    )
}
